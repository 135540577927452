/**
 * Module for header on Inventory Request Detail Header Page
 * @module src/InventoryReqyestDetail/InventoryReturnDetailHeader
 */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import Dropdown from '@salesforce/design-system-react/components/menu-dropdown';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import './index.scss';
import { OPSCSRUSERS, onHoldUsers, holdTypes } from '../../../../util/constants';
import { getEventIcon, getEventIconInner } from '../../../../util/utilityFunctions';
import { GET_SAS_URI } from '../../../../graphql/getSasUri';
import { GET_SHIP_TO_ADDRESSES } from '../../../../graphql/getShipToAddresses';
import DisplayRecordIndicator from '../../../Shared/DisplayRecordIndicator';
import { GET_USER_CC_HOLD } from '../../../../graphql/getUserCCInfo';
import { GET_USER_HOLD } from '../../../../graphql/getUserHold';
import { setHoldBannerStatus } from '../../../../store/ducks/holdBanner';
// import ReadMore from '../../Shared/ReadMore';

interface Props {
  inventoryReturnDetail?: any;
  isEventInventoryFlow?: boolean;
  handleInventoryReturnActionDropdownSelect?: any;
  hasEventInventory?: boolean;
  isOrder?: boolean;
  handleRequestClick?: any;
  detailsViewconfig?: any;
  isFailed?: boolean;
  viewOnly?: boolean;
}

interface File {
  imageurl: string;
  name: string;
}

const InventoryReturnDetailHeader: FC<Props> = ({
  inventoryReturnDetail,
  handleInventoryReturnActionDropdownSelect,
  isOrder,
  handleRequestClick,
  detailsViewconfig,
  isFailed,
  viewOnly,
}) => {
  const {
    status,
    type = 'Inventory Return',
    orderNumber,
    accountId,
    salesRepName,
    accountName,
    accountNumber,
    visualIndicator,
    // trackingNumber,
    shipmentDate,
    comments,
    // returnReason,
    attachments,
    lineItems,
    // territoryNumber,
    shipToId,
    liftGateNeeded,
    dockAvailable,
    shortTruckOnly,
    onsiteContractEmail,
    onsiteContractInstructions,
    onsiteContractName,
    onsiteContractPhone,
    returnType,
    diplayOrderType,
    isshippingLabelRequired,
    shippingLabelAttachments,
    caseNumber,
    salesRep,
    parentEventId,
    parentEventType,
    parentCaseExternalId,
    parentEventSubType,
    // orderType,
    failureReason,
    shippingAddessLine1,
    shippingAddessLine2,
    shippingAddressNumber,
    shippingCity,
    shippingPostalcode,
    shippingState,
    salesRepNameIns,
    salesRepEmailIns,
    salesRepPhoneIns,
  } = inventoryReturnDetail;
  const history = useHistory();
  const showReturnType = isOrder ? diplayOrderType : returnType;
  const dispatch = useDispatch();
  // const isPickUp = isOrder
  //   ? orderType === 'Pickup Return'
  //     ? 'Pickup Return'
  //     : 'Standard Return'
  //   : type === 'Pickup Return'
  //   ? 'Pickup Return'
  //   : 'Standard Return';
  const [getShipToAddressItems, { data: shipToAddressesItems }] = useLazyQuery(
    GET_SHIP_TO_ADDRESSES,
    {
      fetchPolicy: 'no-cache',
    }
  ); // USELAZYQUERY

  const userInfo = useSelector(getUserInfo);
  const isHoldUser = !!onHoldUsers?.find((rec: any) => rec?.value === userInfo?.personas);
  const [userOnHold, setUserOnHold] = useState(false);
  const isUserOpsCsr = OPSCSRUSERS?.find((rec: any) => rec?.value === userInfo?.personas)
    ? true
    : false;
  const { data: sasUriData } = useQuery(GET_SAS_URI);
  const { data: holddata } = useQuery(GET_USER_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
    },
  });
  const { data: accountHoldFlag } = useQuery(GET_USER_CC_HOLD, {
    fetchPolicy: 'no-cache',
    variables: {
      id: userInfo?.id,
      accountId: accountNumber,
      name: salesRepName,
    },
  });
  useEffect(() => {
    getShipToAddressItems({
      variables: {
        accountId,
      },
    });
  }, [getShipToAddressItems, accountId]);
  useEffect(() => {
    if (
      accountHoldFlag?.getCycleCountHoldInfo &&
      accountHoldFlag?.getCycleCountHoldInfo?.length > 0
    ) {
      if (accountHoldFlag?.getCycleCountHoldInfo[0]?.onHold === true && isHoldUser) {
        setUserOnHold(true);
        dispatch(setHoldBannerStatus(true));
      } else {
        dispatch(setHoldBannerStatus(false));
      }
    }
    if (holddata?.getUserHoldInfo && holddata?.getUserHoldInfo?.length > 0) {
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        returnType === 'Pickup Return' &&
        !userInfo?.allowPickupReturn
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
      if (
        holddata?.getUserHoldInfo[0]?.onHoldReason.filter(
          (e: any) =>
            e === holdTypes[0]['Regulatory Noncompliance'] ||
            e === holdTypes[0]['Sunshine Act Hold']
        )?.length > 0 &&
        returnType === 'Inventory Return' &&
        !userInfo?.allowStandardReturn
      ) {
        setUserOnHold(holddata?.getUserHoldInfo[0]?.onHold);
        dispatch(setHoldBannerStatus(holddata?.getUserHoldInfo[0]?.onHold));
      }
    }
  }, [accountHoldFlag, dispatch, isHoldUser, holddata, userInfo, returnType]);

  const handleNavigateToEventList = (): void => {
    history.push('/Inventory/Returns');
  };
  let cancelCheck = true;
  if (lineItems && lineItems.length > 0) {
    cancelCheck = lineItems.every(
      (item: any) =>
        item.workflowAction === 'Reject' ||
        item.workflowAction === 'Cancel' ||
        visualIndicator.toLowerCase() === 'new'
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const shipTo =
    shipToAddressesItems &&
    shipToAddressesItems.getShipToAddresses.filter((e: any) => e.id === shipToId);
  const parenticonPath = getEventIconInner(parentEventType);
  const dropdown = [
    {
      disabled:
        status === 'Failed' && isUserOpsCsr
          ? false || viewOnly
          : !!(
              visualIndicator &&
              (visualIndicator.toLowerCase() === 'cancelled' ||
                visualIndicator.toLowerCase() === 'completed' ||
                visualIndicator.toLowerCase() === 'closed' ||
                visualIndicator.toLowerCase() === 'shipped' ||
                visualIndicator.toLowerCase() === 'requested' ||
                visualIndicator?.toLowerCase() === 'label requested' ||
                visualIndicator.toLowerCase() === '3pl review' ||
                isOrder)
            ),
      label: 'Edit',
      value: 'edit',
    },
    {
      disabled:
        status === 'Failed' && isUserOpsCsr
          ? false || viewOnly
          : !!(
              (userOnHold && isHoldUser) ||
              (visualIndicator &&
                (visualIndicator.toLowerCase() === 'cancelled' ||
                  visualIndicator.toLowerCase() === 'completed' ||
                  visualIndicator.toLowerCase() === 'closed' ||
                  visualIndicator.toLowerCase() === 'shipped' ||
                  visualIndicator.toLowerCase() === 'requested' ||
                  visualIndicator?.toLowerCase() === 'label requested' ||
                  visualIndicator.toLowerCase() === '3pl review' ||
                  isOrder))
            ),
      disabledReason: lineItems.length === 0 ? 'missing_line_items' : 'nothing',
      label: 'Submit',
      value: status === 'Failed' ? 'resubmit' : 'submit',
    },
    {
      disabled:
        status === 'Failed' && isUserOpsCsr
          ? false || viewOnly
          : !!(
              !cancelCheck ||
              (visualIndicator &&
                (visualIndicator.toLowerCase() === 'cancelled' ||
                  visualIndicator.toLowerCase() === 'completed' ||
                  visualIndicator.toLowerCase() === 'closed' ||
                  visualIndicator.toLowerCase() === 'shipped' ||
                  visualIndicator.toLowerCase() === 'requested' ||
                  visualIndicator.toLowerCase() === '3pl review')) ||
              isOrder
            ),
      label: 'Cancel',
      value: 'cancel_request',
    },
    // {
    //   label: 'Discrepancy',
    //   value: 'discrepancy',
    // },
  ];

  const actions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup
        className="slds-align-middle"
        variant="list"
        id="button-group-page-header-actions"
      >
        <Dropdown
          style={{ width: '6rem', marginLeft: '10px', borderRadius: '0.25rem' }}
          iconCategory="utility"
          iconSize="small"
          align="right"
          iconName="down"
          iconPosition="right"
          label="Actions"
          options={dropdown}
          onSelect={(value: string): void => handleInventoryReturnActionDropdownSelect(value)}
        />
        <DisplayRecordIndicator recordInfo={inventoryReturnDetail} />
      </ButtonGroup>
    </PageHeaderControl>
  );

  const handleAttachmentsView = (file: File): void => {
    const url = `${file.imageurl.split('?')[0]}?${sasUriData?.getSasUri.sasUri}`;
    window.open(url, '_blank');
  };

  const handleChangeRequest = (): void => {
    handleRequestClick(parentEventType, parentCaseExternalId, parentEventId);
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const getConfiguration = (key: string) => {
    if (detailsViewconfig) {
      return detailsViewconfig?.some(
        (orderConfig: { mappingKey: string; isShow: boolean }) =>
          orderConfig.mappingKey === key && orderConfig.isShow === true
      );
    }
    return true;
  };
  const iconPath = getEventIcon(type);

  let returnStatus = visualIndicator;

  if (visualIndicator === 'Rejected' || visualIndicator === 'Approved') {
    returnStatus = status;
  } else if (
    status === 'Failed' &&
    (visualIndicator === 'Failed' || visualIndicator === 'Closed')
  ) {
    returnStatus = 'Approved';
  }
  return (
    <IconSettings iconPath="/icons">
      <div className="slds-x-small-buttons_horizontal" style={{ padding: '10px' }}>
        <Button
          style={{ color: 'black' }}
          iconCategory="utility"
          iconName="back"
          iconPosition="left"
          label="Back"
          onClick={handleNavigateToEventList}
        />
      </div>
      <PageHeader
        className="slds-m-around_small slds-m-bottom_none"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        label={
          showReturnType === 'Pickup Return' ? 'Pickup Return' : 'Standard Return'
          //  isOrder
          //   ? orderType === 'Pickup Return'
          //     ? 'Pickup Return'
          //     : 'Standard Return'
          //   : type === 'Pickup Return'
          //   ? 'Pickup Return'
          //   : 'Standard Return'
        }
        onRenderActions={actions}
        title={
          <div className="slds-grid slds-grid_vertical-align-center slds-p-top_xx-small">
            <span className="slds-col event_id">
              {isOrder ? (caseNumber ? caseNumber : orderNumber || 'TBD') : orderNumber || 'TBD'}
            </span>
            <span className="slds-col slds-m-left_large">
              <div style={{ display: 'flex' }}>
                <EventStatusBadge status={returnStatus} />
                {visualIndicator === 'Rejected' && (
                  <div style={{ marginLeft: '5px' }}>
                    <Icon
                      title="Rejected"
                      category="utility"
                      name="warning"
                      size="x-small"
                      style={{ color: '#b83c27' }}
                      className="slds-icon-text-error"
                    />
                  </div>
                )}
              </div>
            </span>
          </div>
        }
        variant="record-home"
      />
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {getConfiguration('salesRep') && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div className="slds-text-title" title="Sales Rep" style={{ fontWeight: 'bold' }}>
              Sales Rep
            </div>
            <div className="slds-tooltip-trigger">
              <div title={salesRepName || salesRep}>{salesRepName || salesRep}</div>
              <span />
            </div>
          </li>
        )}
        {getConfiguration('account') || getConfiguration('primaryAccount') ? (
          <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Account"
              style={{ fontWeight: 'bold' }}
            >
              Account
            </div>
            <div className="" title="--">
              {`${accountNumber} - ${accountName}`}
            </div>
          </li>
        ) : (
          ''
        )}
        {showReturnType !== 'Pickup Return'
          ? getConfiguration('requestedReceivalDate') && (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Expected Return Date"
                  style={{ fontWeight: 'bold' }}
                >
                  Expected Return Date
                </div>
                <p className="slds-line-clamp">
                  {shipmentDate
                    ? moment(shipmentDate)
                        .utc()
                        .format('MM/DD/YYYY')
                    : '--'}
                </p>
              </li>
            )
          : ''}
        {getConfiguration('shipTo') && (
          <li
            className="slds-page-header__detail-block slds-col slds-size_3-of-12"
            style={{ overflowWrap: 'break-word' }}
          >
            <div className="slds-text-title" title="Ship Date" style={{ fontWeight: 'bold' }}>
              Ship To
            </div>
            <div style={{ width: '100%', fontSize: '16px' }} title="">
              {shippingAddessLine1
                ? `${
                    shippingAddressNumber ? `${shippingAddressNumber} -` : ''
                  } ${shippingAddessLine1 || ''}, ${shippingAddessLine2 || ''}, ${shippingCity ||
                    ''}, ${shippingState || ''}, ${shippingPostalcode || ''}, ${
                    !shippingAddressNumber ? 'US' : ''
                  }`
                : '--'}
            </div>
          </li>
        )}
      </ul>
      <ul
        style={{ borderBottom: 'none' }}
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
      >
        {showReturnType !== 'Pickup Return' &&
          isFailed &&
          getConfiguration('isshippingLabelRequired') && (
            <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Request Shipping Label"
                style={{ fontWeight: 'bold' }}
              >
                Request Shipping Label
              </div>
              <div className="" title="--">
                {isshippingLabelRequired ? 'Yes' : 'No'}
              </div>
            </li>
          )}
        {showReturnType !== 'Pickup Return' &&
          isFailed &&
          isshippingLabelRequired &&
          getConfiguration('isshippingLabelRequired') && (
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Shipping Label"
                style={{ fontWeight: 'bold' }}
              >
                Shipping Label
              </div>
              {showReturnType !== 'Pickup Return' &&
              shippingLabelAttachments &&
              shippingLabelAttachments.length > 0 ? (
                shippingLabelAttachments.map((item: any) => {
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(): void => handleAttachmentsView(item)}
                      aria-hidden="true"
                    >
                      {`${item.name}`}
                    </div>
                  );
                })
              ) : (
                <div title="">--</div>
              )}
            </li>
          )}

        {showReturnType === 'Pickup Return'
          ? getConfiguration('account') && (
              <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Requested Pickup Date"
                  style={{ fontWeight: 'bold' }}
                >
                  Requested Pickup Date
                </div>
                <p className="slds-line-clamp">
                  {shipmentDate
                    ? moment(shipmentDate)
                        .utc()
                        .format('MM/DD/YYYY')
                    : '--'}
                </p>
              </li>
            )
          : getConfiguration('isshippingLabelRequired') &&
            !isFailed && (
              <li className="slds-page-header__detail-block slds-col slds-size_4-of-12">
                <div
                  className="slds-text-title slds-truncate"
                  title="Request Shipping Label"
                  style={{ fontWeight: 'bold' }}
                >
                  Request Shipping Label
                </div>
                <div className="" title="--">
                  {isshippingLabelRequired ? 'Yes' : 'No'}
                </div>
              </li>
            )}
        {showReturnType !== 'Pickup Return' &&
          !isFailed &&
          isshippingLabelRequired &&
          getConfiguration('isshippingLabelRequired') && (
            <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
              <div
                className="slds-text-title slds-truncate"
                title="Shipping Label"
                style={{ fontWeight: 'bold' }}
              >
                Shipping Label
              </div>
              {showReturnType !== 'Pickup Return' &&
              shippingLabelAttachments &&
              shippingLabelAttachments.length > 0 ? (
                shippingLabelAttachments.map((item: any) => {
                  return (
                    <div
                      style={{ cursor: 'pointer' }}
                      onClick={(): void => handleAttachmentsView(item)}
                      aria-hidden="true"
                    >
                      {`${item.name}`}
                    </div>
                  );
                })
              ) : (
                <div title="">--</div>
              )}
            </li>
          )}
        {getConfiguration('Attachments') && isFailed && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Attachment"
              style={{ fontWeight: 'bold' }}
            >
              Attachments
            </div>
            {attachments.length > 0 ? (
              attachments.map((item: any) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(): void => handleAttachmentsView(item)}
                    aria-hidden="true"
                  >
                    {`${item.name}`}
                  </div>
                );
              })
            ) : (
              <div title="">--</div>
            )}
          </li>
        )}
      </ul>
      {showReturnType === 'Pickup Return' && (
        <ul
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
          style={{ borderBottom: 'none' }}
        >
          {getConfiguration('account') && (
            <li className="slds-page-header__detail">
              <div
                className="slds-text-title slds-truncate"
                title="Pickup Details"
                style={{ fontWeight: 'bold' }}
              >
                Pickup Return Details
              </div>
              <div style={{ fontSize: '16px' }} title="Specific Shipping Details">
                <span>
                  {` Sales Rep Name: ${
                    salesRepNameIns && salesRepNameIns !== 'null' ? salesRepNameIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Contact Phone: ${
                    salesRepPhoneIns && salesRepPhoneIns !== 'null' ? salesRepPhoneIns : ''
                  }`}
                </span>
                <span>
                  {` | Sales Rep Email: ${
                    salesRepEmailIns && salesRepEmailIns !== 'null' ? salesRepEmailIns : ''
                  }`}
                </span>
                <span>{` | Lift Gate Needed: ${liftGateNeeded ? 'Yes' : 'No'}`}</span>
                <span>{` | Dock Available: ${dockAvailable ? 'Yes' : 'No'}`}</span>
                <span>{` | Short Truck Only: ${shortTruckOnly ? 'Yes' : 'No'}`}</span>
                <span>{` | Onsite Contact Name: ${onsiteContractName || ''}`}</span>
                <span>{` | Onsite Contact Phone: ${onsiteContractPhone || ''}`}</span>
                {<span>{` | Onsite Contact Email: ${onsiteContractEmail || ''}`}</span>}
                {<span>{` | Onsite Instructions: ${onsiteContractInstructions || ''}`}</span>}
              </div>
            </li>
          )}
        </ul>
      )}
      <ul
        className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none slds-m-bottom_none"
        style={{ borderBottom: 'none' }}
      >
        {getConfiguration('Attachments') && !isFailed && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Attachment"
              style={{ fontWeight: 'bold' }}
            >
              Attachments
            </div>
            {attachments.length > 0 ? (
              attachments.map((item: any) => {
                return (
                  <div
                    style={{ cursor: 'pointer' }}
                    onClick={(): void => handleAttachmentsView(item)}
                    aria-hidden="true"
                  >
                    {`${item.name}`}
                  </div>
                );
              })
            ) : (
              <div title="">--</div>
            )}
          </li>
        )}
        {getConfiguration('comments') && !isFailed && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Header Comments"
              style={{ fontWeight: 'bold' }}
            >
              Header Comments
            </div>
            {comments && comments.length
              ? comments.filter((item: { type: string }) => item?.type === 'Internal')?.[0]
                  ?.value || '--'
              : '--'}
          </li>
        )}
        {getConfiguration('comments') && isFailed && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Header Comments"
              style={{ fontWeight: 'bold' }}
            >
              Header Comments
            </div>
            {comments && comments.length
              ? comments.filter((item: { type: string }) => item?.type === 'Internal')?.[0]
                  ?.value || '--'
              : '--'}
          </li>
        )}
        <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
          <div
            className="slds-text-title slds-truncate"
            title="Comment"
            style={{ fontWeight: 'bold' }}
          >
            Related Documents
          </div>
          {parentEventId ? (
            <div title={parentEventId}>
              <img src={parenticonPath} alt="company" />
              <span style={{ margin: '5px 5px 5px 5px' }}>
                {parentEventType === 'Demo' && parentEventSubType === 'Demo'
                  ? 'Standard Demo'
                  : parentEventSubType || parentEventType}
              </span>
              <span
                role="button"
                tabIndex={0}
                className="slds-button"
                onClick={handleChangeRequest}
                onKeyDown={handleChangeRequest}
                style={{ cursor: 'pointer' }}
              >
                {parentEventId}
              </span>
            </div>
          ) : (
            '--'
          )}
        </li>
        {isFailed && (
          <li className="slds-page-header__detail-block slds-col slds-size_3-of-12">
            <div
              className="slds-text-title slds-truncate"
              title="Failure Reason"
              style={{ fontWeight: 'bold' }}
            >
              Failure Reason
            </div>
            <div title={failureReason}>{failureReason}</div>
          </li>
        )}
      </ul>
    </IconSettings>
  );
};

/** Custom Header on Inventory Request Detail Header Page **/
export default InventoryReturnDetailHeader;
