/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable */
/**
 * Module for showing Inventorys with their status.
 * @module src/components/Inventory/index
 */
/* eslint-disable react/jsx-wrap-multilines */
import React, { FC, useState, useCallback, useEffect } from 'react';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from '@salesforce/design-system-react/components/spinner';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Button from '@salesforce/design-system-react/components/button';
import Tabs from '@salesforce/design-system-react/components/tabs';
import TabsPanel from '@salesforce/design-system-react/components/tabs/panel';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { getUserId } from '../../../store/ducks/userId';
import { GET_FILTER_PRESETS } from '../../../graphql/getFilterPresets';
import { DEFAULT_OFFSET, LIMIT, NEXT_DATA, NonSalesRep, PREV_DATA } from '../../../util/constants';
import { getHoldIcon } from '../../../util/utilityFunctions';
import Icon from '@salesforce/design-system-react/components/icon';
import { setInventoryFilters, getInventoryFilters } from '../../../store/ducks/inventoryFilters';
import { GET_SUB_INVENTORY, SubInventoryData } from '../../../graphql/getSubInventories';
import { GET_PRODUCT_LIST } from '../../../graphql/getProductList';
import InventoryHeader from '../InventoryHeader';
import FilterInventory from '../FilterInventory';
import './index.scss';
import FilterTags from '../../Shared/FilterTags';
import InventoryListAccountView from './inventoryListAccountView';
import { GET_ACCOUNTS } from '../../../graphql/getAccounts';
import { GET_VIEW_BYPART_ACCOUNTS_PARTS } from '../../../graphql/getViewByPartAndAccount';
import { GET_PRIMARY_ACCOUNT } from '../../../graphql/getPrimaryAccount';
import { GET_WORKFLOW_PERMISSION } from '../../../graphql/workflowPermissions';
import { setHoldBannerStatus } from '../../../store/ducks/holdBanner';
import InventoryReturn from '../InventoryReturns/InventoryReturn';
import NewRepTransfer from '../Transfer/NewRepTransfer';
import { GET_FORM_CONFIGURATIONS } from '../../../graphql/getFormConfigurations';
import InventoryRequest from '../InventoryRequest';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';
import { InventoryModule } from '../InventoryHeader/interfaces';
import { DynamicValue, Filter, FilterPreset, Filters, SelectedData } from './interfaces';

const Inventory: FC<RouteComponentProps> = ({ history }) => {
  const [isSearch, setIsSearch] = useState(false);
  const [getSubInventories, { data, loading }] = useLazyQuery(GET_SUB_INVENTORY, {
    fetchPolicy: 'no-cache',
  });
  const [
    getViewByPartAndAccount,
    { data: getInventories, loading: loadingInventories },
  ] = useLazyQuery(GET_VIEW_BYPART_ACCOUNTS_PARTS, {
    fetchPolicy: 'no-cache',
  });
  const userInfo = useSelector(getUserInfo);
  const holdIconPath = getHoldIcon();
  const [status, setStatus] = useState('By Account');
  const [subInventories, setsubInventories] = useState<SubInventoryData[]>([]);
  // const [selectedsubInventory, setsubInventory] = useState<SubInventoryData[]>([]);
  const [inventoriesView, setInventoriesView] = useState<SubInventoryData[]>([]);
  // const [sortedSubInventories, setSortedSubInventories] = useState<SubInventoryData[]>([]);
  const [searchTerm, setSearchText] = useState('');
  const [offset, setOffset] = useState(DEFAULT_OFFSET);
  // const [salesRepValue, setSalesRepValue] = useState<any[] | null>();
  const [selectedAccount, setSelectedAccount] = useState<any[] | null>();
  const [selectedPart, setSelectedPart] = useState<any[] | null>();
  const [selectedFilterApiValues, setSelectedFilterApiValues] = useState<Filters | null>(null);
  const [filterInventoryModalVisible, setFilterInventoryModalVisible] = useState(false);
  const [isEditFilter, setIsEditFilter] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState<FilterPreset | DynamicValue | null>(null);
  const [updatedSelectedFilter, setUpdatedSelectedFilter] = useState<
    FilterPreset | DynamicValue | null
  >(null);
  const [productList, setProductList] = useState<any[]>([]);
  const [lastAccountList, setAccountList] = useState<any[]>([]);
  const [isSearchHappen, setSearchHappen] = useState(false);
  const [productListItems, { data: productItems }] = useLazyQuery(GET_PRODUCT_LIST);
  const [
    getSubInventoryWithLotDetails,
    { data: subInventoryWithLotDetails, loading: loadingSubInventory },
  ] = useLazyQuery(GET_SUB_INVENTORY, { fetchPolicy: 'no-cache' });
  const userId = useSelector(getUserId);
  const [getAccountItems, { data: accountItems }] = useLazyQuery(GET_ACCOUNTS, {
    fetchPolicy: 'no-cache',
    variables: {
      userId,
    },
  });
  const { data: isBizAdminUser } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'is_business_admin_user',
    },
  });

  const [getFormConfigurations, { data: formData }] = useLazyQuery(GET_FORM_CONFIGURATIONS);
  const [showRequest, setShowRequest] = useState(false);
  const [showReturns, setShowReturns] = useState(false);
  const [showTransfer, setShowTransfer] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [requestType, setRequestType] = useState<string>('');

  useEffect(() => getFormConfigurations(), [getFormConfigurations]);

  const handleInventoryActions = (value: InventoryModule): void => {
    setRequestType(value.label);
    if(value.value.includes('Transfer')){
      handleTransferToggle();
    } else if(value.value.includes('Return')){
      handleReturnsToggle();
    } else {
      handleRequestToggle();
    }
  }

  const handleRequestToggle = (): void => {
    setShowRequest(!showRequest);
  };

  const handleReturnsToggle = (): void => {
    setShowReturns(!showReturns);
  };

  const handleTransferToggle = (): void => {
    setShowTransfer(!showTransfer);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    if(requestType.includes('Transfer')){
      handleTransferToggle();
    } else if(requestType.includes('Return')){
      handleReturnsToggle();
    } else {
      handleRequestToggle();
    }
  };

  const isUserOps = NonSalesRep?.find((rec: any) => rec?.value === userInfo?.personas)
    ? true
    : false;

  const dispatch = useDispatch();
  dispatch(setHoldBannerStatus(false));

  const optionsWithLabel = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return {
        ...elem,
        label: `${elem.productNumber} ${elem.productDescription}`,
        value: `${elem.productNumber} ${elem.productDescription}`,
      };
    });

  const optionsWithLabelAccount = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return {
        ...elem,
        label:
          elem?.isRepStockAccount === true && !isUserOps
            ? ` * ${elem.number} ${elem.value}`
            : ` ${elem.number} ${elem.value}`,
      };
    });

  const [
    getPrimaryAccountItems,
    { data: primaryAccountItems, loading: loadingPrimaryAccounts },
  ] = useLazyQuery(GET_PRIMARY_ACCOUNT, {
    fetchPolicy: 'no-cache',
  }); // USELAZYQUERY
  const accountItemsData = primaryAccountItems?.getPrimaryAccount;
  useEffect(() => {
    getPrimaryAccountItems({
      variables: {
        salesRepId: selectedFilterApiValues?.salesRepId
          ? selectedFilterApiValues?.salesRepId
          : isUserOps
            ? ''
            : userInfo?.id,
        limit: 500,
      },
    });
  }, [getPrimaryAccountItems, userInfo, selectedFilterApiValues]);

  const fetchItems = useCallback(() => {
    getAccountItems();
    getSubInventoryWithLotDetails({
      variables: {
        salesRepId: isUserOps ? '' : userInfo?.id,
        filters: {},
      },
    });
  }, [getAccountItems, getSubInventoryWithLotDetails, userInfo]); // USELAZYQUERY
  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  useEffect(() => {
    if (productItems && productItems.getProductList && productItems.getProductList.length) {
      setProductList(productItems.getProductList);
    } else {
      setProductList([]);
    }
  }, [productItems]);
  const partsData = optionsWithLabel(subInventoryWithLotDetails?.getSubInventoryList);
  //const handleAPICall =(View: string): void =>{}

  const handlePaginationButtonClicked = (type: string): void => {
    let finalOffset;
    if (type === NEXT_DATA) {
      finalOffset = offset + LIMIT;
    } else {
      finalOffset = offset - LIMIT;
    }
    if (status === 'By Account') {
      getViewByPartAndAccount({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByAccount',
          accountnum: (selectedAccount && selectedAccount[0] && selectedAccount[0].number) || '',
          subInventoryName:
            (selectedAccount && selectedAccount?.length > 0 && selectedAccount[0]?.value) || '',
          filters: selectedFilterApiValues,
          searchText: searchTerm,
        },
      });
    } else {
      getViewByPartAndAccount({
        variables: {
          offset: finalOffset,
          limit: LIMIT,
          productNumber: (selectedPart && selectedPart[0] && selectedPart[0].productNumber) || '',
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByParts',
          filters: selectedFilterApiValues,
          searchText: searchTerm,
        },
      });
    }
    setOffset(finalOffset);
  };

  useEffect(() => {
    if (accountItemsData && accountItemsData.length > 0 && status === 'By Account') {
      const accountData = optionsWithLabelAccount(accountItemsData);
      setAccountList(accountData);
      const repStockAccount = accountData?.filter((rec: any) => rec.isRepStockAccount === true);
      const { number, label, value } = repStockAccount[0] || '';
      if (
        userInfo &&
        userInfo?.personas &&
        !isUserOps &&
        repStockAccount &&
        repStockAccount.length > 0
      ) {
        if (!isSearchHappen) {
          // setSelectedAccount([{ number, label, value }]);
          getViewByPartAndAccount({
            variables: {
              offset: 0,
              limit: LIMIT,
              businessUnit: [userInfo?.businessunit],
              salesRepId: userId,
              screenType: 'ByAccount',
              accountnum: repStockAccount[0]?.number,
              subInventoryName:
                (repStockAccount && repStockAccount?.length > 0 && repStockAccount[0]?.value) || '',
              filters: selectedFilterApiValues,
              searchText: searchTerm,
            },
          });
        }
      }
    }
  }, [accountItemsData]);

  const getInventoryListFormatted = useCallback((items: any[]): any[] => {
    if (items && items.length > 0) {
      const list = items.map((item: any) => {
        return {
          ...item,
        };
      });
      return list;
    }
    return [];
  }, []);

  useEffect(() => {
    setIsSearch(false);
    if (
      getInventories &&
      getInventories?.getViewByPartAndAccount &&
      getInventories?.getViewByPartAndAccount.length > 0
    ) {
      const list = getInventoryListFormatted(getInventories.getViewByPartAndAccount);
      setInventoriesView(list);
    } else {
      setInventoriesView([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getInventories, getViewByPartAndAccount, getInventoryListFormatted]);

  const filterStore = useSelector(getInventoryFilters);

  useEffect(() => {
    let setFilters = JSON.parse(filterStore || '{}');

    if (setFilters && setFilters.filterValues) {
      setUpdatedSelectedFilter(setFilters);
      setSelectedFilter(setFilters);
      const filters = getFilterApiValue(setFilters.filterValues);
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
    }
  }, []);

  const handleEditFilter = (editFilter: boolean): void => {
    setIsEditFilter(editFilter);
  };
  const handleSelect = (selection: any): void => {
    if (status === 'By Account') {
      setSelectedAccount(selection);
    } else {
      setSelectedPart(selection);
    }
    if (status === 'By Account') {
      setSearchHappen(true);
      if (selection.length === 0) {
        getPrimaryAccountItems({
          variables: {
            salesRepId: selectedFilterApiValues?.salesRepId
              ? selectedFilterApiValues?.salesRepId
              : isUserOps
                ? ''
                : userInfo?.id,
            limit: 500,
          },
        });
      }
      getViewByPartAndAccount({
        variables: {
          offset: 0,
          limit: LIMIT,
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByAccount',
          accountnum: (selection && selection[0] && selection[0].number) || '',
          subInventoryName: (selection && selection?.length > 0 && selection[0]?.value) || '',
          filters: selectedFilterApiValues,
          searchText: searchTerm,
        },
      });
    } else {
      getViewByPartAndAccount({
        variables: {
          offset: 0,
          limit: LIMIT,
          productNumber: (selection && selection[0] && selection[0].productNumber) || '',
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByParts',
          filters: selectedFilterApiValues,
          searchText: searchTerm,
        },
      });
    }
  };
  const handleToggleFilterInventory = (): void => {
    handleEditFilter(false);
    setSelectedFilter(updatedSelectedFilter);
    setFilterInventoryModalVisible(!filterInventoryModalVisible);
  };
  const handleEditPress = (filter: FilterPreset, isEditPress: boolean): void => {
    setSelectedFilter(filter);
    setFilterInventoryModalVisible(!filterInventoryModalVisible);
    if (isEditPress) {
      handleEditFilter(true);
    }
  };
  const handleInventorySearch = (name: string) => {
    setIsSearch(true);
    setSearchText(name);
    let filters: Filters = { ...selectedFilterApiValues };
    setSelectedFilterApiValues(filters);
    if (status === 'By Account') {
      getViewByPartAndAccount({
        variables: {
          offset: 0,
          limit: LIMIT,
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByAccount',
          accountnum: (selectedAccount && selectedAccount[0] && selectedAccount[0].number) || '',
          subInventoryName:
            (selectedAccount && selectedAccount?.length > 0 && selectedAccount[0]?.value) || '',
          filters,
          searchText: name,
        },
      });
    } else {
      getViewByPartAndAccount({
        variables: {
          offset: 0,
          limit: LIMIT,
          productNumber: (selectedPart && selectedPart[0] && selectedPart[0].productNumber) || '',
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByParts',
          filters,
          searchText: name,
        },
      });
    }
    if (data && data.getSubInventoryList) {
      setsubInventories(data.getSubInventoryList);
    }
  };
  const getFilterApiValue = (filterValues: DynamicValue): Filters => {
    let reducedFilterValues = {};

    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key]) && key !== 'e') {
          let values;
          // These are the only two fields which we need to send the name not Id.
          values = filterValues[key].map((item: SelectedData) => item.id);
          if (values && values.length > 0) {
            if (key === 'salesRepId') {
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values][0] };
            } else {
              reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
            }
          }
          if (key === 'salesRepId') {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values][0] };
          } else {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (Array.isArray(filterValues[key]) && filterValues[key]) {
          if (filterValues[key].length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilterInventory = (
    filterValues: Filter | DynamicValue | null,
    filterName?: string
  ): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setUpdatedSelectedFilter(filter);
    setSelectedFilter(filter);
    if (filterValues) {
      let filters: Filters = getFilterApiValue(filterValues);
      if (selectedFilterApiValues?.searchText)
        filters.searchText = selectedFilterApiValues.searchText;
      setSelectedFilterApiValues(filters);
      handleRefetchItems(filters);
      dispatch(setInventoryFilters(JSON.stringify(filters)));
    } else {
      setSelectedFilterApiValues(
        selectedFilterApiValues?.searchText
          ? { searchText: selectedFilterApiValues?.searchText }
          : null
      );
      handleRefetchItems(
        selectedFilterApiValues?.searchText
          ? { searchText: selectedFilterApiValues?.searchText }
          : null
      );
    }
  };

  const handleRefetchItems = (filterData: Filters | null): void => {
    setOffset(0);
    if (status === 'By Account') {
      getViewByPartAndAccount({
        variables: {
          offset: 0,
          limit: LIMIT,
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByAccount',
          accountnum: (selectedAccount && selectedAccount[0] && selectedAccount[0].number) || '',
          subInventoryName:
            (selectedAccount && selectedAccount?.length > 0 && selectedAccount[0]?.value) || '',
          filters: filterData,
          searchText: searchTerm,
        },
      });
    } else {
      getViewByPartAndAccount({
        variables: {
          offset: 0,
          limit: LIMIT,
          productNumber: (selectedPart && selectedPart[0] && selectedPart[0].productNumber) || '',
          businessUnit: [userInfo?.businessunit],
          salesRepId: userId,
          screenType: 'ByParts',
          filters: filterData,
          searchText: searchTerm,
        },
      });
    }
  };
  const resetFilters = useCallback((): void => {
    setSelectedFilter(null);
    setUpdatedSelectedFilter(null);
    setIsEditFilter(false);
    setSelectedFilterApiValues(null);
    setOffset(0);
  }, [setOffset]);

  const { data: filterPresets, refetch: refetchFilterPresets } = useQuery(GET_FILTER_PRESETS, {
    variables: {
      userId,
    },
  });
  const handleDeleteFilterPreset = (externalId: string): void => {
    const filterExternalId = selectedFilter ? selectedFilter.filterExternalId : null;
    if (filterExternalId && filterExternalId === externalId) {
      resetFilters();
    }
  };

  const handleCompletedTab = (e: any): void => {
    setSelectedFilter(null);
    setSelectedFilterApiValues(null);
    setSearchText('');
    setUpdatedSelectedFilter(null);
    if (e === 0) {
      setStatus('By Account');
      history.push('/Inventory');
    } else if (e === 1) {
      setStatus('By Part');
      history.push('/Inventory/ByPart');
    } else {
      setStatus('By Account');
      history.push('/Inventory');
    }
  };
  const filterData = filterPresets?.getFilterPresets;
  const showMyInventory =
    userInfo &&
      userInfo.isSubInventoryOnhold &&
      (userInfo.personas === 'Sales Rep' || userInfo.personas === 'Sales Associate')
      ? false
      : true;
  return (
    <div>
      <InventoryHeader
        selectedAccount={selectedAccount}
        selectedPart={selectedPart}
        accountItemsData={optionsWithLabelAccount(accountItemsData ? accountItemsData : lastAccountList)}
        handleSelect={handleSelect}
        onEditPress={handleEditPress}
        handleInventorySearch={handleInventorySearch}
        handleToggleFilterInventory={handleToggleFilterInventory}
        resetFilters={resetFilters}
        filterPresets={filterData}
        handleFilterInventory={handleFilterInventory}
        partsData={partsData}
        userView={status}
        salesRepId={
          selectedFilterApiValues?.salesRepId
            ? selectedFilterApiValues?.salesRepId
            : isUserOps
              ? ''
              : userInfo?.id
        }
        getPrimaryAccountItems={getPrimaryAccountItems}
        getSubInventoryWithLotDetails={getSubInventoryWithLotDetails}
        invData={inventoriesView}
        setSearchHappen={setSearchHappen}
        loadingPrimaryAccounts={loadingPrimaryAccounts}
        handleInventoryActions={handleInventoryActions}
      />
      {filterInventoryModalVisible && (
        <FilterInventory
          handleToggleFilterInventory={handleToggleFilterInventory}
          filterInventoryModalVisible={filterInventoryModalVisible}
          selectedFilter={selectedFilter}
          refetchFilterPresets={refetchFilterPresets}
          handleFilterInventory={handleFilterInventory}
          handleEditFilter={handleEditFilter}
          isEditFilter={isEditFilter}
          handleClearFilter={handleDeleteFilterPreset}
          userView={status}
          accountItemsData={accountItemsData}
          loadingPrimaryAccounts={loadingPrimaryAccounts}
        />
      )}
      {selectedFilter && selectedFilter.filterValues && (
        <FilterTags selectedFilter={selectedFilter} handleFilter={handleFilterInventory} />
      )}

      <IconSettings iconPath="/icons">
        <div className="container">
          {loading || loadingInventories ? (
            <Spinner
              size="large"
              variant="base"
              assistiveText={{ label: 'Loading...' }}
              key="spinner"
            />
          ) : (
            <>
              <Tabs
                variant="scoped"
                id="inventory-tabs-scoped"
                onSelect={(e: any): void => handleCompletedTab(e)}
                selectedIndex={status === 'By Part' ? 1 : 0}
              >
                <TabsPanel label="By Account">
                  <InventoryListAccountView
                    userView={status}
                    gridData={inventoriesView}
                    selectedAccount={selectedAccount}
                    selectedPart={[]}
                  />
                </TabsPanel>
                <TabsPanel label="By Part">
                  <InventoryListAccountView
                    userView={status}
                    gridData={inventoriesView}
                    selectedAccount={[]}
                    selectedPart={selectedPart}
                  />
                </TabsPanel>
                <TabsPanel
                  disabled={true}
                  onClick={(e: any) => {
                    e.preventDefault();
                  }}
                ></TabsPanel>
                <TabsPanel
                  disabled={true}
                  onClick={(e: any) => {
                    e.preventDefault();
                  }}
                ></TabsPanel>
                <TabsPanel
                  disabled={true}
                  onClick={(e: any) => {
                    e.preventDefault();
                  }}
                  label={
                    <div style={{ display: 'inline-block', pointerEvents: 'none' }}>
                      <span style={{ marginLeft: '0px', pointerEvents: 'none' }}>
                        <Icon
                          category="utility"
                          name="notification"
                          size="x-small"
                          style={{ fill: '#000084', marginRight: '5px' }}
                        />{' '}
                        Expiring in 90 days{' '}
                      </span>
                      <span style={{ marginLeft: '30px' }}>
                        <Icon
                          category="utility"
                          name="clock"
                          size="x-small"
                          style={{ fill: '#FFAE42', marginRight: '5px' }}
                        />
                        30 days
                      </span>
                      <span style={{ marginLeft: '30px' }}>
                        <Icon
                          category="utility"
                          name="warning"
                          size="x-small"
                          style={{ fill: '#D2042D', marginRight: '5px' }}
                        />
                        Expired
                      </span>
                      <span style={{ marginLeft: '30px', marginRight: '5px' }}>
                        <img
                          src={holdIconPath}
                          className="logo-image"
                          alt="company"
                          style={{ marginLeft: '8px', marginRight: '8px' }}
                        />
                        On Hold
                      </span>
                    </div>
                  }
                ></TabsPanel>
              </Tabs>
              <div className="slds-grid slds-grid_align-center slds-grid_vertical-align-center slds-p-top_medium">
                <div className="slds-col">
                  <Button
                    assistiveText={{ icon: 'Icon Bare Small' }}
                    iconCategory="utility"
                    iconName="chevronleft"
                    iconSize="small"
                    disabled={!(offset > 0)}
                    iconVariant="bare"
                    // will fetch the previous Inventorys data
                    onClick={(): void => handlePaginationButtonClicked(PREV_DATA)}
                    variant="icon"
                  />
                </div>
                <div className="slds-col slds-p-left_medium slds-p-right_medium">
                  <span>{`${offset + 1} - ${offset + LIMIT}`}</span>
                </div>
                <div className="slds-col">
                  <Button
                    assistiveText={{ icon: 'Icon Bare Small' }}
                    iconCategory="utility"
                    iconName="chevronright"
                    iconSize="small"
                    iconVariant="bare"
                    disabled={getInventories && getInventories?.getViewByPartAndAccount.length < 50}
                    // will fetch the next Inventorys data
                    onClick={(): void => handlePaginationButtonClicked(NEXT_DATA)}
                    variant="icon"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </IconSettings>
      {showRequest && <InventoryRequest
        isOpen={showRequest}
        toggleOpen={handleRequestToggle}
        formData={formData}
        eventType={requestType}
        handleCancelButton={handleCancelModal}
      />}
      {showReturns && <InventoryReturn
        isOpen={showReturns}
        toggleOpen={handleReturnsToggle}
        formData={formData}
        eventType={requestType}
        returnsType={requestType}
        handleCancelButton={handleCancelModal}
      />}
      {showTransfer && <NewRepTransfer
        isOpen={showTransfer}
        toggleOpen={handleTransferToggle}
        formData={formData}
        transferType={requestType}
        handleCancelButton={handleCancelModal}
        handleRefetchItems={()=>{}}
      />}
      {canceModalVisible && <CancelEventModal
          isInventory
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
      />}
    </div>
  );
};
export default withRouter(Inventory);
