/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-lonely-if */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars*/
/**
 * React component for Inventory Request.
 * @module src/components/InventoryRequest/index
 */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isBefore, format, isEqual } from 'date-fns';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Spinner from '@salesforce/design-system-react/components/spinner';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Modal from '@salesforce/design-system-react/components/modal';
import Button from '@salesforce/design-system-react/components/button';
import Combobox from '@salesforce/design-system-react/components/combobox';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';
import Radio from '@salesforce/design-system-react/components/radio-group/radio';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { escapeRegExp } from 'lodash';
import CREATE_CYCLE_COUNT from '../../../graphql/mutations/createCycleCount';
import { GET_USER_TERRITORIES } from '../../../graphql/getUserTerritories';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import {
  COUNT_TYPE_ACTIONS,
  COUNT_TYPE_ACTIONS_NON_SM,
  NOTIFY_TYPE,
} from '../../../util/constants';
import SnackBar from '../../Shared/SnackBar';
import { getUserInfo } from '../../../store/ducks/userInfo';
import DatePicker from './DatePicker';
import { GET_ELIGIBLE_REPS_ACCOUNTS } from '../../../graphql/getEligibleRepsAccountsForCycleCount';
import { GET_ELIGIBLE_ACCOUNTS } from '../../../graphql/getEligibleAccounts';

interface Props extends RouteComponentProps {
  children?: FC;
  isOpen: boolean;
  isClone?: boolean;
  toggleOpen: () => void;
  formData?: any;
  eventType: string;
  history: any;
  handleCancelButton?: () => void;
  refetchEventDetails?: () => void;
  flowType?: boolean;
  eventExternalId?: string;
  isEventInvClone?: boolean;
  cycleCountDetail?: any;
  refetch?: () => void;
  allLineItemsClone?: any;
  inventoryRequestFav?: any;
  type?: string;
}
interface ValueInterface {
  value: string;
}
interface DropdownSelect {
  id: string;
  label?: string;
  value: string;
}
interface Item {
  hospital?: string;
  id?: string;
  owned?: string;
  value: string;
  subInventoryCode?: string;
}

const NewCycleCount: FC<Props> = ({
  cycleCountDetail,
  isOpen,
  toggleOpen,
  handleCancelButton,
  refetch,
  eventType,
  history,
}) => {
  const { open, notification, openSnackBar } = useSnackBar();
  const [
    getTerritories,
    { data: territoryData, loading: isTerritoryLoad },
  ] = useLazyQuery(GET_USER_TERRITORIES, { fetchPolicy: 'no-cache' });
  const userInfo = useSelector(getUserInfo);
  const [
    getSalesRepsItems,
    { data: salesRepsData, loading: loadingSalesRep },
  ] = useLazyQuery(GET_ELIGIBLE_REPS_ACCOUNTS, { fetchPolicy: 'no-cache' });
  const [
    getEligibleAccounts,
    { data: eligibleAccountsData, loading: loadingEligibleAccounts },
  ] = useLazyQuery(GET_ELIGIBLE_ACCOUNTS, { fetchPolicy: 'no-cache' });
  const [showAlert, setShowAlert] = useState(true);
  const [createCycleCount, { data: createCycleCountData }] = useMutation(CREATE_CYCLE_COUNT);
  const [loading, setLoading] = useState(false);
  const [failedPopup, setFailedPopup] = useState(false);
  const [saveEnable, setSaveEnable] = useState(false);
  const [startDate, setStartDate] = useState<any>(null);
  const [territory, setTerritory] = useState<any>(null);
  const [accounts, setAccounts] = useState<any>(null);
  const [comments, setComments] = useState<any>(null);
  const [inputValue, setInputValue] = useState('');
  const [inputValueAccount, setInputValueAccount] = useState('');
  const [openDropDown, setOpenDropDown] = useState(false);
  const [territoryValidation, setTerritoryValidation] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [salesRepOptions, setSalesRepOptions] = useState<any[]>();
  const [accountOptions, setAccountOptions] = useState<any>();
  const [salesRepValue, setSalesRepValue] = useState<any>();
  const [countType, setCountType] = useState('Blind');
  const [oldStartDate, setOldStartDate] = useState('');
  const [commentText, setCommentText] = useState(cycleCountDetail?.comments[0]?.value);
  const isOps = userInfo?.personas?.toLowerCase()?.includes('op');
  const SPORTS_MED = '';
  const optionsWithLabel = (value: any): object =>
    value &&
    // eslint-disable-next-line consistent-return
    value
      .map(
        (elem: {
          ithasinventory: any;
          __typename: string;
          name: any;
          number: any;
          value: any;
          id: any;
          subInventoryCode: any;
        }) => {
          // eslint-disable-next-line no-underscore-dangle
          if (elem.__typename === 'UserTerritory') {
            // return { ...elem, label: `${elem?.value}`, disabled: !elem?.ithasinventory };
            return {
              ...elem,
              label: `${elem?.value}`,
              id: elem?.ithasinventory
                ? `itHasInventory_enabled_${elem.id}`
                : `itHasInventory_disabled_${elem.id}`,
            };
          }
          // eslint-disable-next-line no-underscore-dangle
          if (elem.__typename === 'EligibleAccounts') {
            return {
              ...elem,
              label: `${elem?.number} - ${elem?.name}`,
              id: elem?.ithasinventory
                ? `itHasInventory_enabled_${elem.id}`
                : `itHasInventory_disabled_${elem.id}`,
              // disabled: !elem?.ithasinventory,
            };
          }
          return {
            ...elem,
            label: `${elem?.number} - ${elem?.name}`,
          };
        }
      )
      .sort((a: any, b: any) => {
        // Sort in ascending order based on itHasInventory
        return b.itHasInventory - a.itHasInventory;
      });

  const dateCheck = (date: any): boolean => {
    const currentTime = new Date(moment().format('YYYY-MM-DD'));
    const selecetdTime = new Date(moment(date).format('YYYY-MM-DD'));
    const isBeforeDate = isBefore(selecetdTime, currentTime);
    return isBeforeDate;
  };

  const isEqualCheck = (date: any): boolean => {
    const currentTime = new Date(moment().format('YYYY-MM-DD'));
    const selecetdTime = new Date(moment(date).format('YYYY-MM-DD'));
    const isEqualDate = isEqual(selecetdTime, currentTime);
    return isEqualDate;
  };

  const getDate = useCallback((date: any): string => {
    const formattedSelectedDate = new Date(date);
    const finalDate = format(new Date(formattedSelectedDate), 'yyyy-MM-dd');
    return finalDate;
  }, []);

  const optionsWithLabelForSalesRep = (arr: []): any[] =>
    arr &&
    arr.map((elem: any) => {
      return { ...elem, label: `${elem?.name}` };
    });

  useEffect(() => {
    getTerritories();
  }, [getTerritories]);

  useEffect(() => {
    if (cycleCountDetail) {
      setOldStartDate(
        `${moment(cycleCountDetail?.startDate).format('MM/DD/YYYY')} - ${moment(
          cycleCountDetail?.endDate
        ).format('MM/DD/YYYY')}`
      );
    }
  }, [cycleCountDetail]);

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    array.filter((item: any) => {
      const i = newArray.findIndex(x => x?.number === item?.number);
      if (i <= -1) {
        newArray.push(item);
      }
      return null;
    });
    return newArray;
  };

  useEffect(() => {
    if (salesRepsData?.getEligibleCycleCountAccountDetails) {
      setSalesRepOptions(
        optionsWithLabelForSalesRep(salesRepsData?.getEligibleCycleCountAccountDetails)
      );
    }
    if (eligibleAccountsData?.getEligibleCycleCountAccountsForUsers) {
      setAccountOptions(
        optionsWithLabel(
          removeDuplicates(eligibleAccountsData?.getEligibleCycleCountAccountsForUsers)
        )
      );
    }
  }, [eligibleAccountsData, salesRepsData]);

  useEffect(() => {
    // getTerritories();
    if (eventType === 'Edit' && cycleCountDetail) {
      setEndDate(
        cycleCountDetail.endDate
          ? new Date(cycleCountDetail.endDate).toLocaleDateString()
          : new Date().toLocaleTimeString()
      );
      setStartDate(
        cycleCountDetail.startDate
          ? new Date(cycleCountDetail.startDate).toLocaleDateString()
          : new Date().toLocaleDateString()
      );
      setTerritory([{ label: cycleCountDetail?.territory, value: cycleCountDetail?.territory }]);
      // if (userInfo?.businessunit !== SPORTS_MED) {
      setAccounts([
        {
          label: `${cycleCountDetail?.inventoryLocation} - ${cycleCountDetail?.territory}`,
          value: cycleCountDetail?.territory,
        },
      ]);

      setSalesRepValue([
        {
          value: cycleCountDetail?.salesRepDetails[0]?.salesRepName,
          label: cycleCountDetail?.salesRepDetails[0]?.salesRepName,
          id: cycleCountDetail?.salesRepDetails[0]?.salesRepId,
        },
      ]);
      setCountType(cycleCountDetail?.countType);
      // }
    }
  }, [eventType, setEndDate, setStartDate, cycleCountDetail, userInfo, comments]);

  useEffect(() => {
    if (endDate && startDate) {
      const updateStartDate = typeof startDate === 'string' ? new Date(startDate) : startDate;
      const updateEndDate = typeof endDate === 'string' ? new Date(endDate) : endDate;

      const formattedStartDate = new Date(moment(updateStartDate).format('YYYY-MM-DD'));
      const formattedEndDate = new Date(moment(updateEndDate).format('YYYY-MM-DD'));

      const isBeforeDate = isBefore(formattedEndDate, formattedStartDate);
      if (isBeforeDate) {
        openSnackBar(NOTIFY_TYPE.ERROR, 'End date cannot be less than start date.');
        setShowAlert(false);
        setSaveEnable(false);
      } else {
        setShowAlert(true);
        getSalesRepsItems({
          variables: {
            startDate,
            endDate,
          },
        });
      }
    }
  }, [endDate, startDate, openSnackBar, getSalesRepsItems]);

  const handleModalToggle = useCallback((): void => {
    setFailedPopup(!failedPopup);
  }, [failedPopup]);

  useEffect(() => {
    if (startDate && endDate && (territory || (accounts && salesRepValue && showAlert))) {
      setSaveEnable(true);
    } else {
      setSaveEnable(false);
    }
  }, [endDate, startDate, territory, showAlert, accounts, salesRepValue]);

  // const handleSelect = (data: any, name: string) => {
  //   const { selection } = data;
  //   setTerritory(selection);
  // };

  const handleRemoveSelectionSR = useCallback((_event, data: any) => {
    const { selection } = data;
    setInputValue('');
    setSalesRepValue(selection);
  }, []);

  useEffect(() => {
    if (salesRepValue) {
      getEligibleAccounts({
        variables: {
          salesRepId: salesRepValue[0]?.id || '',
          startDate,
          endDate,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEligibleAccounts, salesRepValue]);

  const handleSelectItem = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => Object.assign(acc, { [cur.id]: cur }), {})
      );
      setTerritory(updatedSelection);
    },
    [setInputValue, setTerritory]
  );

  const handleSelectItemAccount = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValueAccount('');
      const updatedSelection: Selection[] = Object.values(
        selection.reduce((acc: any, cur: any) => {
          const modifiedId = cur.id.replace(/itHasInventory_(enabled_|disabled_)/, '');
          acc[modifiedId] = { ...cur, id: modifiedId };
          return acc;
        }, {})
      );
      setAccounts(updatedSelection);
    },
    [setInputValueAccount]
  );

  const territoryOptions = optionsWithLabel(territoryData?.getUserTerritories);
  const handleChangeDate = (selectedDate: any, name: string): void => {
    if (selectedDate) {
      const utcDate = getDate(selectedDate);
      if (name === 'startDate') {
        setStartDate(utcDate);
      } else {
        setEndDate(utcDate);
      }
    } else {
      if (name === 'startDate') {
        setStartDate(null);
      } else {
        setEndDate(null);
      }
    }
  };

  const handleRemoveSelection = useCallback(
    (_event, data: any) => {
      const { selection } = data;
      setInputValue('');
      setTerritory(selection);
    },
    [setTerritory]
  );

  const handleRemoveSelectionAccount = useCallback(
    (_event, data: any) => {
      if (eventType === 'Edit') {
        _event.preventDefault();
      } else {
        const { selection } = data;
        setInputValueAccount('');
        setAccounts(selection);
      }
    },
    [setAccounts, eventType]
  );

  const handleChange = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleRadioButtonChange = (event: any): void => {
    setCountType(event.target.value);
  };

  const handleSaveButton = () => {
    const territoryNames = accounts?.map((item: any) => item.name);
    const subInventoryIds = accounts?.map((item: any) => item.number);
    const accountIds = accounts?.map((item: any) => {
      return {
        id: item.id,
        number: item.number,
      };
    });
    // if (userInfo?.businessunit === SPORTS_MED) {
    //   territoryNames = territory?.map((item: any) => item.value);
    //   subInventoryIds = territory?.map((item: any) => item.subInventoryCode);
    // } else {
    // territoryNames =
    // subInventoryIds =
    // }
    let mutation: any = {};
    if (eventType === 'New') {
      mutation = {
        caseId: null,
        caseExternalId: null,
        cycleCountExternalId: null,
        cycleCountId: null,
        countType,
        formType: 'New',
        territoryNames,
        subInventoryIds,
        accountIds,
        inventoryConsignmentHold: true,
        reasonCodeForUnscannedItems: false,
        startDate,
        endDate,
        oldDate: null,
        salesRepId: salesRepValue ? salesRepValue[0]?.id : '',
        division: userInfo?.businessunit,
      };
    }
    if (eventType === 'Edit') {
      mutation = {
        caseId: null,
        caseExternalId: cycleCountDetail.caseExternalId,
        cycleCountExternalId: cycleCountDetail.cycleCountExternalId,
        cycleCountId: cycleCountDetail.cycleCountId,
        formType: 'Update',
        territoryNames,
        subInventoryIds,
        accountIds,
        oldDate: oldStartDate,
        inventoryConsignmentHold: true,
        reasonCodeForUnscannedItems: false,
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
        ...(cycleCountDetail?.status?.toLowerCase() === 'submitted' &&
          isOps && {
            submitType: 'OPSEdit',
          }),
        comments: [
          {
            comment: commentText,
            postTime: moment(new Date()).format('YYYY-MM-DD HH:MM'),
            commentType: 'Internal',
            externalId: cycleCountDetail?.comments[0]?.externalId,
          },
        ],
      };
    }

    setLoading(true);
    createCycleCount({
      variables: mutation,
    })
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.createCycleCount &&
          response.data.createCycleCount.message === 'success'
        ) {
          setSaveEnable(false);
          setLoading(true);
          openSnackBar(NOTIFY_TYPE.SUCCESS, 'Cycle Count created successfully');
          if (eventType === 'Edit') {
            if (refetch) {
              toggleOpen();
              refetch();
            }
          } else {
            history.push('/cycleCountDetails', {
              cycleCountExternalId: response.data.createCycleCount.externalId,
            });
          }
        }
      })
      .catch(error => {
        setLoading(false);
        const errors = error.graphQLErrors.map((er: any) => er.message);
        const getTerritory = errors[0]
          .split('{')
          .pop()
          .split('}')[0];
        const errMsg = getTerritory.split(':');
        if (errMsg && errMsg[errMsg.length - 1]) {
          setFailedPopup(true);
          setTerritoryValidation(errMsg[errMsg.length - 1]);
        }
      });
  };

  const handleSelect = useCallback((data: any, name: string) => {
    const { selection } = data;
    if (name === 'salesRep') {
      setSalesRepValue(selection);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeOnType = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      _event.persist();
      setInputValue(value);
    },
    [setInputValue]
  );

  const handleChangeOnAccount = useCallback(
    (_event, selectedValue: ValueInterface) => {
      const { value } = selectedValue;
      const modifiedString = value.replace(/itHasInventory_(enabled_|disable_)/g, '');

      _event.persist();
      setInputValueAccount(modifiedString);
    },
    [setInputValueAccount]
  );

  const comboboxFilterAndLimit = (parameters: any): any => {
    const { searchedValue, options, selection } = parameters;
    const inputValueRegExp = new RegExp(escapeRegExp(searchedValue), 'ig');
    return options.filter((option: any) => {
      const searchTermFound = option?.label ? option?.label.match(inputValueRegExp) : false;
      const isSeparator = false;
      const notAlreadySelected = !selection?.some((sel: any) => sel?.id === option?.id);
      return (!searchedValue || isSeparator || searchTermFound) && notAlreadySelected;
    });
  };

  const changeCommentData = (event: any): void => {
    const { value } = event.target;
    if (value === '') setCommentText('');
    else setCommentText(value);
  };

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {loading && (
        <Spinner
          size="small"
          variant="base"
          assistiveText={{ label: 'Cycle Count creation Loading...' }}
        />
      )}
      <Modal
        className="form-engine-modal"
        isOpen={isOpen}
        onRequestClose={handleCancelButton}
        ariaHideApp
        heading={
          <div className="slds-float_left slds-text-title_bold">
            {eventType === 'New' ? 'Create Cycle Count' : 'Edit Cycle Count'}
          </div>
        }
        footer={[
          <Button label="Cancel" key="cancel" onClick={handleCancelButton} />,
          <Button
            label={eventType === 'New' ? 'Initiate' : 'Save'}
            variant="brand"
            onClick={handleSaveButton}
            disabled={!saveEnable}
            key="save_transfer"
          />,
        ]}
        dismissOnClickOutside={false}
      >
        <section className="slds-p-around_medium slds-theme_shade" key="form">
          <div className="slds-text-body_regular slds-p-bottom_large surgery-field-label">
            (*Required fields)
          </div>
          <div>
            <div
              style={{ padding: 20, paddingBottom: 40, backgroundColor: 'white' }}
              className="slds-form-element__control "
            >
              {/* {userInfo?.businessunit !== SPORTS_MED && ( */}
              <div className="input-container">
                <div className="slds-grid">
                  <RadioGroup
                    className="slds-grid"
                    labels={{ label: 'Count Type' }}
                    required
                    onChange={(event: any): void => {
                      handleRadioButtonChange(event);
                    }}
                    disabled={!isOps}
                  >
                    {COUNT_TYPE_ACTIONS_NON_SM.map(value => (
                      <Radio
                        key={value}
                        id={value}
                        labels={{ label: value }}
                        value={value}
                        checked={countType === value}
                        variant="base"
                        size="medium"
                        className="radio-group-with-space"
                      />
                    ))}
                  </RadioGroup>
                </div>
              </div>
              {/* )} */}
              <div className="input-container">
                <DatePicker
                  label="Start Date"
                  placeholder="mm/dd/yyy"
                  value={startDate}
                  handleChangeDate={(result: any): void => {
                    handleChangeDate(result, 'startDate');
                  }}
                  disabled={
                    (eventType === 'Edit' && (dateCheck(startDate) || isEqualCheck(startDate))) ||
                    !isOps ||
                    cycleCountDetail?.status?.toLowerCase() === 'submitted'
                  }
                  required
                />
              </div>
              <div className="input-container">
                <DatePicker
                  label="End Date"
                  placeholder="mm/dd/yyy"
                  value={endDate}
                  handleChangeDate={(result: any): void => {
                    handleChangeDate(result, 'endDate');
                  }}
                  disabled={
                    dateCheck(endDate) ||
                    !isOps ||
                    cycleCountDetail?.status?.toLowerCase() === 'submitted'
                  }
                  required
                />
              </div>
              {/* {userInfo?.businessunit === SPORTS_MED && (
                <div className="input-container">
                  <RadioGroup
                    labels={{ label: 'Count Type' }}
                    // onChange={(event) => this.setState({ checked: event.target.value })}
                    required
                    disabled={!isOps}
                  >
                    {COUNT_TYPE_ACTIONS.map(value => (
                      <Radio
                        key={value}
                        id={value}
                        labels={{ label: value }}
                        value={value}
                        checked
                        variant="base"
                        size="large"
                        disable={!isOps}
                      />
                    ))}
                  </RadioGroup>
                </div>
              )} */}
              {/* {userInfo?.businessunit === SPORTS_MED && (
                <div
                  style={{ paddingTop: 20, pointerEvents: !isOps ? 'none' : 'auto' }}
                  className={`input-container ${!isOps ? 'disabled-input input' : ''}`}
                >
                  <Combobox
                    id="territory"
                    optionsAddItem={[]}
                    events={{
                      onRequestOpen: (): void => {
                        setOpenDropDown(true);
                      },
                      onRequestClose: (): void => {
                        setOpenDropDown(false);
                      },
                      onChange: handleChange,
                      onRequestRemoveSelectedOption: handleRemoveSelection,
                      onSelect: handleSelectItem,
                    }}
                    isOpen={openDropDown}
                    labels={{
                      label: `Territory`,
                      placeholder: 'Select',
                      noOptionsFound: 'No data found',
                    }}
                    options={territoryOptions || []}
                    selection={territory || []}
                    multiple
                    menuItemVisibleLength={5}
                    menuPosition="relative"
                    value={inputValue}
                    variant="readonly"
                    disabled={!isOps}
                    required
                  />
                </div>
              )} */}
              {/* {userInfo?.businessunit !== SPORTS_MED && ( */}
              <>
                <div
                  style={{ paddingTop: 20, pointerEvents: !isOps ? 'none' : 'auto' }}
                  className={`input-container ${!isOps ? 'disabled-input input' : ''}`}
                >
                  <Combobox
                    id="sales Rep"
                    optionsAddItem={[]}
                    singleInputDisabled={eventType === 'Edit'}
                    events={{
                      onChange: handleChangeOnType,
                      // onRequestOpen: (): void => {
                      //   setOpenDropDown(true);
                      // },
                      // onRequestClose: (): void => {
                      //   setOpenDropDown(false);
                      // },
                      onSelect: (event: any, data: any): void => handleSelect(data, 'salesRep'),
                      onRequestRemoveSelectedOption: handleRemoveSelectionSR,
                    }}
                    // isOpen={openDropDown}
                    labels={{
                      label: `Sales Rep`,
                      placeholder: 'Select Sales Rep',
                      noOptionsFound: 'No data found',
                    }}
                    hasInputSpinner={eventType === 'Edit' ? '' : loadingSalesRep}
                    // options={salesRepOptions || []}
                    options={
                      eventType === 'Edit'
                        ? []
                        : comboboxFilterAndLimit({
                            searchedValue: inputValue,
                            options: salesRepOptions || [],
                            selection: salesRepValue,
                          })
                    }
                    selection={salesRepValue || []}
                    menuItemVisibleLength={5}
                    menuPosition="relative"
                    variant="inline-listbox"
                    value={inputValue}
                    className={eventType === 'Edit' ? 'custom-combobox' : ''}
                    required
                  />
                </div>
                <div
                  style={{ paddingTop: 20, pointerEvents: !isOps ? 'none' : 'auto' }}
                  className={`input-container ${!isOps ? 'disabled-input input' : ''}`}
                >
                  <Combobox
                    id="Account"
                    optionsAddItem={[]}
                    events={{
                      // onRequestOpen: (): void => {
                      //   setOpenDropDownAccount(true);
                      // },
                      // onRequestClose: (): void => {
                      //   setOpenDropDownAccount(false);
                      // },
                      onChange: handleChangeOnAccount,
                      onRequestRemoveSelectedOption: handleRemoveSelectionAccount,
                      onSelect: handleSelectItemAccount,
                    }}
                    // isOpen={openDropDownAccount}
                    labels={{
                      label: `Account`,
                      placeholder: 'Select Account',
                      noOptionsFound: 'No data found',
                    }}
                    options={
                      eventType === 'Edit'
                        ? []
                        : comboboxFilterAndLimit({
                            searchedValue: inputValueAccount,
                            options: accountOptions || [],
                            selection: accounts,
                          })
                    }
                    multiple
                    hasInputSpinner={eventType === 'Edit' ? '' : loadingEligibleAccounts}
                    // options={accountOptions || []}
                    selection={accounts || []}
                    menuItemVisibleLength={5}
                    menuPosition="relative"
                    value={inputValueAccount}
                    className={eventType === 'Edit' ? 'disabled-options-combobox' : ''}
                    variant={eventType === 'Edit' ? 'readonly' : 'base'}
                    required
                    disabled={eventType === 'Edit'}
                  />
                </div>
              </>
              {/* )} */}
              <div style={{ paddingTop: 20 }} className="slds-form-element slds-m-bottom_small">
                <Checkbox
                  labels={{
                    label: (
                      // userInfo?.businessunit === SPORTS_MED ? (
                      //   <span className="slds-text-body_small slds-text-title_bold">
                      //     Inventory Consignment Hold
                      //   </span>
                      // ) : (
                      <span className="slds-text-body_small slds-text-title_bold">
                        Inventory Hold
                      </span>
                    ),
                    toggleDisabled: '',
                    toggleEnabled: '',
                  }}
                  variant="toggle"
                  // onChange={handleChange}
                  checked
                  disabled
                />
              </div>
              <div style={{ paddingTop: 20 }} className="slds-form-element slds-m-bottom_small">
                <Checkbox
                  labels={{
                    label: (
                      <span className="slds-text-body_small slds-text-title_bold">
                        Reason Code For Unscanned Items
                      </span>
                    ),
                    toggleDisabled: '',
                    toggleEnabled: '',
                  }}
                  variant="toggle"
                  // onChange={handleChange}
                  checked={false}
                  disabled
                />
              </div>
              {eventType === 'Edit' && (
                <div style={{ paddingTop: 20 }} className="slds-form-element slds-m-bottom_small">
                  <h1 className="slds-text-title_bold">Comments</h1>

                  <div className="slds-grid slds-gutters slds-grid_vertical-align-center filter_date">
                    <textarea
                      id="unique-id-1"
                      rows={10}
                      className="slds-textarea"
                      value={commentText || ''}
                      disabled={
                        cycleCountDetail?.status === 'COMPLETED' ||
                        cycleCountDetail?.status === 'CLOSED'
                      }
                      onChange={(event: any): void => changeCommentData(event)}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </Modal>
      <Modal
        isOpen={failedPopup}
        onRequestClose={handleModalToggle}
        dismissOnClickOutside={false}
        ariaHideApp={false}
        heading={
          <div className="slds-align_absolute-center slds-text-title_bold slds-text-heading_small">
            Create Cycle Count
          </div>
        }
        size="small"
        footer={[
          <div
            className="slds-align_absolute-center event_cancel_button"
            key="cancel_return_inventory"
          >
            <Button style={{ backgroundColor: '#ffb501' }} label="Ok" onClick={handleModalToggle} />
          </div>,
        ]}
      >
        <section className="slds-m-around_small slds-align_absolute-center">
          <div className="cancel-success" />
          {`Failed to create Cycle Count as record already exists.`}
        </section>
      </Modal>
    </IconSettings>
  );
};

/** React component for NewCycleCount Request. */
export default withRouter(NewCycleCount);
