/*
 * User Info GraphQL specification
 * @module src/graphql/getUserNotificationDetail
 */
/* eslint-disable import/prefer-default-export */
import gql from 'graphql-tag';

/** User Info GraphQL API query */
export const GET_USER_NOTIFICATION_DETAILS = gql`
  query getUserNotificationDetail($id: String) {
    getUserNotificationDetail(id: $id) {
      id
      userId
      buCatTempId
      subject
      transactionType
      transactionNumber
      transactionSfid
      transactionExternalId
      transactionDate
      notificationCreatedDate
      isStar
      isRead
      details
      screenName
      salesRepName
      createdBy
      tableHeader
      oldDate
      statmentValue
      salesRep
      tableDetails
      account
      territory
      tableValue {
        productNumber
        lotNumber
        Account
        expirationDate
        quantity
      }
      mobileTableValue
      ccLink
      description
    }
  }
`;
