/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unneeded-ternary */
/**
 * Module for header on InventoryRequestDetailCatalog Page
 * @module src/SurgicalDetail/InventoryRequestDetailCatalog
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { FC, ReactNode, useCallback, useState, useEffect } from 'react';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { useMutation, useQuery, useLazyQuery } from '@apollo/react-hooks';
import Input from '@salesforce/design-system-react/components/input';
import Combobox from '@salesforce/design-system-react/components/combobox';
import InputIcon from '@salesforce/design-system-react/components/icon/input-icon';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import ButtonGroup from '@salesforce/design-system-react/components/button-group';
import Button from '@salesforce/design-system-react/components/button';
import Spinner from '@salesforce/design-system-react/components/spinner';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getUserInfo } from '../../../../store/ducks/userInfo';
import CartComponent from './cartComponent';
import './index.scss';
import InventoryReturnAddItems from '../../InventoryReturnDetail/InventoryReturnAddItems';
import EventStatusBadge from '../../../Shared/EventStatusBadge';
import {
  CYCLE_COUNT,
  MAX_QUANTITY,
  NOTIFY_TYPE,
  RETURNS_REASON_OPTION,
  RETURNS_REASON_TYPE,
  RETURNS_TYPE_OPTION_NON_SM,
  SPORTS_MED,
  WORKFLOW_ACTION,
} from '../../../../util/constants';
import {
  getEventIcon,
  getHoldIcon,
  getSvgIcon,
  getDateDifferenceInDays,
} from '../../../../util/utilityFunctions';
import RESUBMIT_ORDER_DETAILS from '../../../../graphql/mutations/resubmitOrderDetails';
import CREATE_UPDATE_USAGE from '../../../../graphql/mutations/createdUpdateUsage';
import CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS from '../../../../graphql/mutations/createUpdateInventoryReturnLineItems';
import SnackBar from '../../../Shared/SnackBar';
import useSnackBar from '../../../../util/customHooks/useSnackBar';
import debounced from '../../../../util/debounced';
import { GET_INVENTORY_RETURN_DETAILS } from '../../../../graphql/getInventoryReturnDetails';
import { GET_ORDER_DETAILS } from '../../../../graphql/getOrderDetails';
import { GET_PARTS_WITH_LOT_DETAILS } from '../../../../graphql/getPartWithLotDetails';
import InputWithAutoSuggestion from '../../../Usage/UsageDetails/UsageTabs/Parts/InputWithAutoSuggestion';

interface Props {
  inventoryDetail?: any;
  isEventInventoryFlow?: boolean;
  refetch: () => void;
  visualIndicator: string;
  type: string;
  hasEventInventory?: any;
  eventInventoryExternalId?: any;
  externalId?: any;
  isOrder?: boolean;
  handleLineItemActions: any;
  viewOnly?: boolean;
}

interface DynamicValue {
  [key: string]: any;
}

interface SelectedData {
  id: string;
  value: string;
  __typename?: string;
}

const InventoryRequestTable: FC<Props> = ({
  refetch,
  inventoryDetail,
  isEventInventoryFlow,
  visualIndicator,
  type,
  handleLineItemActions,
  viewOnly,
  eventInventoryExternalId,
  externalId,
  isOrder,
}) => {
  const userInfo = useSelector(getUserInfo);
  const { open, notification, openSnackBar } = useSnackBar();
  const [searchValue, setSearchText] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [showSearchCatalogModal, setShowSearchCatalogModal] = useState(false);
  const [items, setItems] = useState<any>([]);
  const [lotChange, setLotChange] = useState<any>([]);
  const [selectedFilter, setSelectedFilter] = useState<DynamicValue | null>(null);
  const [, setSelectedFilterApiValues] = useState<DynamicValue | null>(null);
  const [reSubmitInventory] = useMutation(RESUBMIT_ORDER_DETAILS);
  const [isAssignUser, setIsAssignUser] = useState(false);
  const [getPartsLotDetails, { data: partsLotDetails, loading: partsLoader }] = useLazyQuery(
    GET_PARTS_WITH_LOT_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  // const [createUpdateUsage, { data: eventUsageMutation }] = useMutation(CREATE_UPDATE_USAGE);
  const [createUpdateInventoryReturn, { data: createUpdateInventoryReturnMutation }] = useMutation(
    CREATE_UPDATE_INVENTORY_RETURN_LINE_ITEMS
  );
  // const { data: getInventoryRequestDetails, refetch: refetchEventDetails, loading } = useQuery(
  //   GET_INVENTORY_REQUEST_DETAILS,
  //   {
  //     fetchPolicy: 'no-cache',
  //     variables: {
  //       externalId:
  //         isEventInventoryFlow || hasEventInventory ? eventInventoryExternalId : externalId,
  //       searchText: searchValue,
  //     },
  //   }
  // );
  const { data: getInventoryRequestDetails, refetch: refetchEventDetails, loading } = useQuery(
    GET_INVENTORY_RETURN_DETAILS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: externalId,
      },
    }
  );
  const {
    data: getOrderDetails,
    refetch: refetchProcessedEventDetails,
    loading: processedLoading,
  } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'no-cache',
    variables: {
      id: externalId,
      searchText: searchValue,
    },
  });
  const onHandleCloseSearchCatalogModal = (): void => {
    setShowSearchCatalogModal(false);
    if (refetch) {
      refetch();
    }
    setTimeout(() => {
      if (refetch) {
        refetch();
      }
    }, 5000);
  };
  const tableClass = inventoryDetail.returnType === 'Pickup Return' ? 'irpp-cols' : 'irs-cols';
  const isPickupReturnType = inventoryDetail.returnType === 'Inventory Return' ? false : true;
  const isUserOps =
    userInfo?.personas?.toLowerCase().includes('branch op') ||
    userInfo?.personas?.includes('3PL Ops') ||
    userInfo?.personas?.includes('Agency OPS') ||
    userInfo?.personas?.includes('Agency OPS;Agency CSR');
  const isCSROps = userInfo?.personas === 'CSR;Branch Op';
  const is3PLOps = userInfo?.personas === ' 3PL Ops';
  const reviewReturnList =
    userInfo?.businessunit === SPORTS_MED ? RETURNS_REASON_TYPE : RETURNS_TYPE_OPTION_NON_SM;
  const [quantityRecCount, setCartRecCount] = useState(
    inventoryDetail?.receivedLineItems?.reduce(
      (
        accumulator: any,
        current: {
          receivedQuantity: any;
          quantity: any;
        }
      ) => accumulator + Number(current?.receivedQuantity),
      0
    )
  );
  const [quantityCount, setCartCount] = useState(
    inventoryDetail?.lineItems?.reduce(
      (accumulator: any, current: { quantity: any }) => accumulator + Number(current.quantity),
      0
    )
  );
  useEffect(() => {
    if (type === 'Returning') {
      const total = items?.reduce(
        (accumulator: any, current: any) => accumulator + Number(current.counterValue),
        0
      );
      setCartCount(total);
    } else {
      const total = items?.reduce(
        (accumulator: any, current: any) => accumulator + Number(current.counterValue),
        0
      );
      setCartRecCount(total);
    }
  }, [items, type]);

  useEffect(() => {
    const { lineItems } = inventoryDetail;
    if (
      lineItems &&
      lineItems.length > 0 &&
      (inventoryDetail?.accountName || inventoryDetail?.accountNumber)
    ) {
      getPartsLotDetails({
        variables: {
          productNumber: lineItems.map((i: any) => {
            return { productNumber: i.productNumber, sourceLocation: i.sourceLocation };
          }),
          subInventoryName: inventoryDetail?.accountName,
          accountNumber: inventoryDetail?.accountNumber,
          salesRepId: inventoryDetail?.salesRepId,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDetail]);

  useEffect(() => {
    setIsAssignUser(
      type === 'Returning' &&
        inventoryDetail?.assignedOPSId !== userInfo?.id &&
        (userInfo?.personas === 'Agency OPS' ||
          userInfo?.personas === 'Agency CSR' ||
          userInfo?.personas === 'CSR' ||
          userInfo?.personas === 'Agency OPS;Agency CSR' ||
          userInfo?.personas === 'CSR;Branch Op' ||
          userInfo?.personas === 'Branch Op;CSR' ||
          userInfo?.personas === 'Branch Op' ||
          userInfo?.personas === '3PL Ops' ||
          !!userInfo?.personas?.toLowerCase().includes('csr') ||
          !!userInfo?.personas?.toLowerCase().includes('branch op'))
    );
  }, [type, inventoryDetail, userInfo]);

  useEffect(() => {
    if (type === 'Returning') {
      const updateList = inventoryDetail.lineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record.quantity),
        };
      });
      setItems(updateList);
    } else {
      const updateList = inventoryDetail.receivedLineItems.map((record: any) => {
        return {
          ...record,
          changed: false,
          counterValue: Number(record?.receivedQuantity),
        };
      });
      setItems(updateList);
    }
    // setLoader(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inventoryDetail.lineItems, type, inventoryDetail.receivedLineItems]);

  // useEffect(() => {
  //   if (isOrder) {
  //     if (type === 'Returning') {
  //       const updateList = getOrderDetails?.getOrderDetails?.lineItems.map((record: any) => {
  //         return {
  //           ...record,
  //           changed: false,
  //           counterValue: Number(record.quantity),
  //         };
  //       });
  //       setItems(updateList);
  //     } else {
  //       const updateList = getOrderDetails?.getOrderDetails?.receivedLineItems.map(
  //         (record: any) => {
  //           return {
  //             ...record,
  //             changed: false,
  //             counterValue: Number(record.quantity),
  //           };
  //         }
  //       );
  //       setItems(updateList);
  //     }
  //   }
  //   if (type === 'Returning') {
  //     const updateList = getInventoryRequestDetails?.getInventoryReturnDetails?.lineItems.map(
  //       (record: any) => {
  //         return {
  //           ...record,
  //           changed: false,
  //           counterValue: Number(record.quantity),
  //         };
  //       }
  //     );
  //     setItems(updateList);
  //   } else {
  //     const updateList = getInventoryRequestDetails?.getInventoryReturnDetails?.receivedLineItems.map(
  //       (record: any) => {
  //         return {
  //           ...record,
  //           changed: false,
  //           counterValue: Number(record.quantity),
  //         };
  //       }
  //     );
  //     setItems(updateList);
  //   }
  //   setLoader(false);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getInventoryRequestDetails, getOrderDetails, type, isOrder, setItems]);

  const removeDuplicates = (array: any): any => {
    const newArray: any[] = [];
    const duplicateLots: any[] = [];
    array.forEach((item: any) => {
      const records = array.filter((x: any) => x?.lotNumber === item?.lotNumber);
      if (records?.length === 1) {
        newArray.push(item);
      } else if (records?.length > 1 && duplicateLots.indexOf(item?.lotNumber) === -1) {
        const subInventoryTypes = ['IT', 'IH', 'E'];
        // eslint-disable-next-line no-restricted-syntax
        for (const types of subInventoryTypes) {
          const foundInventory = records.find(
            (ele: any) =>
              ele?.caseUsageSubInventoryCode?.startsWith(types) ||
              ele?.subInventoryCode?.startsWith(types)
          );
          if (foundInventory && Number(foundInventory.availableqty) > 0) {
            newArray.push(foundInventory);
            duplicateLots.push(item.lotNumber);
            break;
          }
        }
      }
      return null;
    });

    return newArray;
  };

  useEffect(() => {
    if (partsLotDetails?.getPartWithLotDetails) {
      const lotDetails = partsLotDetails.getPartWithLotDetails;
      const lotOptions: any = [];
      lotDetails.forEach((item: any) => {
        let dDiff = 60;
        dDiff = getDateDifferenceInDays(item.expirationDate, new Date().toString());

        const data = {
          ...item,
          label: item.lotNumber,
          value: item.lotNumber,
          expiresInDays: item.expirationDate ? dDiff : 60,
        };
        if (item.expirationDate && Number(item.availableqty) > 0) {
          const expirationDate = new Date(moment(item.expirationDate).format('MM/DD/YYYY'));
          const currentDate = new Date(moment().format('MM/DD/YYYY'));
          let surgeryDate;
          if (inventoryDetail?.eventDateTime) {
            surgeryDate = new Date(moment(inventoryDetail?.eventDateTime).format('MM/DD/YYYY'));
          }
          if (expirationDate >= currentDate) {
            lotOptions.push(data);
          } else if (expirationDate < currentDate && surgeryDate && expirationDate >= surgeryDate) {
            lotOptions.push(data);
          }
        } else if (Number(item.availableqty) > 0) {
          lotOptions.push(data);
        }
      });
      const lineItems = items.map((i: any) => {
        const isLotExist = lotOptions?.find(
          (element: any) =>
            (element.lotNumber === i.lotNumber && Number(i.quantity) > 0 && !i.iscatalog) ||
            (inventoryDetail?.status === 'Failed' &&
              element.lotNumber === i.lotNumber &&
              Number(i.quantity) > 0)
        );

        const alreadyAddedLot = lotDetails?.find(
          (element: any) =>
            (element.lotNumber === i.lotNumber && Number(i.quantity) > 0 && !i.iscatalog) ||
            (inventoryDetail?.status === 'Failed' &&
              element.lotNumber === i.lotNumber &&
              Number(i.quantity) > 0)
        );
        if (
          (alreadyAddedLot && !isLotExist && !i.iscatalog) ||
          (inventoryDetail?.status === 'Failed' && alreadyAddedLot && !isLotExist)
        ) {
          const dDiff = getDateDifferenceInDays(
            alreadyAddedLot.expirationDate,
            new Date().toString()
          );

          const data = {
            ...alreadyAddedLot,
            label: alreadyAddedLot.lotNumber,
            value: alreadyAddedLot.lotNumber,
            expiresInDays: alreadyAddedLot.expirationDate ? dDiff : 60,
          };
          lotOptions.push(data);
        } else if (
          (!alreadyAddedLot && i.lotNumber && i.isValidLotSerialCombination && !i.iscatalog) ||
          (!alreadyAddedLot &&
            i.lotNumber &&
            i.isValidLotSerialCombination &&
            inventoryDetail?.status === 'Failed')
        ) {
          lotOptions.push({
            availableqty: i.availableQuantity,
            expirationDate: i.expirationDate,
            lotNumber: i.lotNumber,
            onhandqty: i.onhandQuantity,
            productDescription: i.productdescription,
            productNumber: i.productNumber,
            sourceLocation: i.sourceLocation,
            label: i.lotNumber,
            value: i.lotNumber,
            caseUsageSubInventoryCode: i.caseUsageSubInventoryCode,
            subInventoryCode: i.caseUsageSubInventoryCode,
          });
        }
        let lotValues = removeDuplicates(
          !i.iscatalog || inventoryDetail?.status === 'Failed'
            ? lotOptions.filter(
                (item: any) =>
                  item?.productNumber === i?.productNumber &&
                  item?.sourceLocation === i.sourceLocation
              )
            : lotOptions.filter(
                (item: any) =>
                  item?.productNumber === i?.productNumber &&
                  Number(item.availableqty) > 0 &&
                  item?.sourceLocation === i.sourceLocation
              )
        );
        // lotvalues should be account specific for returns
        if (
          inventoryDetail?.type === 'Inventory Return' ||
          inventoryDetail?.type === 'Pickup Return' ||
          inventoryDetail?.type === 'Standard Return'
        ) {
          lotValues = lotValues?.filter(
            (item: any) => inventoryDetail?.accountNumber === item?.subInventoryCode
          );
        }

        const maxQuantity = i.lotNumber
          ? Number(i.availableQuantity) + Number(i.quantity)
          : MAX_QUANTITY;
        let isERPOrder = false;
        if (
          type &&
          (type === 'Failed' || type === 'Processed' || type === 'Review') &&
          inventoryDetail?.orderId
        ) {
          isERPOrder = true;
        }
        return {
          ...i,
          uniqueId: isERPOrder ? i.caseUsageExternalId || i.sfid || i.externalId : i.externalId,
          originalQty: i.quantity,
          maxQuantity,
          lotOptions: lotValues,
          selectedLot: lotValues.filter((item: any) => item.lotNumber === i.lotNumber),
        };
      });
      setItems(lineItems);
      setTimeout(() => {
        setLoader(false);
      }, 4000);
      // if (loading) {
      //   setLoader(true);
      // } else {
      //   setLoader(false);
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partsLotDetails, refetch]);

  const calculateActualQuantitity = useCallback((item: any, onHandQuantity: any) => {
    const isPackContent = item?.salesUnitOfMeasure === 'PK' || item?.salesUnitOfMeasure === 'BX';
    if (!isPackContent) {
      return onHandQuantity;
    }
    const packOfContentCount = item?.packContent > 0 ? item?.packContent || 1 : 1;
    if (item?.productFamily === 'SPTSIMPLANTS') {
      return onHandQuantity * packOfContentCount;
    }
    return onHandQuantity;
  }, []);
  const holdIconPath = getHoldIcon();
  const catalogInd = getSvgIcon('catalog');
  const formatLineItemToReSubmit = (lineItemsArray: any): any => {
    const finalLineItems: Array<any> = [];
    lineItemsArray.map(
      (item: {
        isLotNumber: any;
        productId: any;
        productNumber: any;
        quantity: any;
        actualQuantity: any;
        externalId: any;
        lotNumber: any;
        sfid: any;
        unitPrice: any;
        caseUsageExternalId: any;
        priceBookEntryId: any;
        salesUnitOfMeasure: any;
        deliveredDate: any;
        caseUsageId: any;
        sourceLocation: any;
        // eslint-disable-next-line array-callback-return
      }): any => {
        finalLineItems.push({
          caseUsageId: item.caseUsageId,
          caseUsageExternalId: item.caseUsageExternalId,
          priceBookEntryId: item.priceBookEntryId,
          salesUnitOfMeasure: item.salesUnitOfMeasure,
          deliveredDate: item.deliveredDate || null,
          productId: item.productId,
          productNumber: item.productNumber,
          quantity: item.quantity,
          actualQuantity: item.actualQuantity,
          isLotNumber: item?.isLotNumber,
          lotNumber: item.lotNumber || '',
          externalId: item.externalId || null,
          sfid: item.sfid || '',
          unitPrice: item.unitPrice || '',
          sourceLocation: item.sourceLocation,
        });
      }
    );
    return finalLineItems;
  };

  const handleUpdates = (lotValCase = false): void => {
    // setLoader(true);
    if (type === 'Failed') {
      // resubmitInventory();
      const payload: Array<any> = [];
      items.forEach((product: any): void => {
        if (product.changed) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            sfid: product.sfid,
            productId: product.productId,
            quantity: product.counterValue.toString(),
            actualQuantity: actualQuantity.toString(),
            productNumber: product.productNumber,
            priceBookEntryId: product.priceBookEntryId,
            salesUnitOfMeasure: product.salesUnitOfMeasure,
            sourceLocation: product.sourceLocation,
            lotNumber: product.lotNumber,
            deliveredDate: null,
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            ...(product.caseUsageId && { caseUsageId: product.caseUsageId }),
            ...(product.caseUsageExternalId && {
              caseUsageExternalId: product.caseUsageExternalId,
            }),
          });
        }
      });
      const mutation = {
        id: inventoryDetail.id,
        caseId: inventoryDetail.caseId,
        caseExternalId: inventoryDetail.caseExternalId,
        externalId: inventoryDetail.externalId,
        shippingId: inventoryDetail.shippingId,
        accountId: inventoryDetail.accountId,
        billingId: inventoryDetail.billingId,
        shipMethod: inventoryDetail.shipMethod,
        // sourceLocation: inventoryDetail?.sourceLocation,
        serviceLevel: inventoryDetail.serviceLevel,
        salesRepId: inventoryDetail.salesRepId,
        receivingRepId: inventoryDetail.receivingRepId,
        fromSubInventoryCode: inventoryDetail.fromSubInventoryCode,
        toSubInventoryCode: inventoryDetail.toSubInventoryCode,
        salesOrganization: inventoryDetail.salesOrganization,
        needByDate: inventoryDetail.needByDate,
        requestedFrom: 'InventoryRequest',
        submitType: 'Update',
        // comments: userComment,
        lineItems: formatLineItemToReSubmit(payload),
      };
      setLoader(true);
      reSubmitInventory({
        variables: mutation,
      }).then((res: any) => {
        refetch();
        if (res && res.data && res.data.resubmitOrderDetails?.message === NOTIFY_TYPE.SUCCESS) {
          setLoader(false);
          // openSnackBar(NOTIFY_TYPE.SUCCESS, 'Successfully deleted line item');
          // if (refetch) {
          // refetch();
          // }
        }
      });
    } else {
      const payload: Array<any> = [];
      items?.forEach((product: any): void => {
        if (product.changed || product.uniqueId || lotValCase) {
          const actualQuantity = calculateActualQuantitity(product, product.counterValue);
          payload.push({
            productId: product.productId,
            externalId: product.externalId,
            id: product?.id,
            returnReason: product.returnReason
              ? typeof product.returnReason === 'string'
                ? product.returnReason
                : product.returnReason[0].value
              : null,
            trackingNumber: product.trackingNumber,
            subInventoryCode: product?.caseUsageSubInventoryCode || '',
            quantity: product.counterValue.toString(),
            productNumber: product.productNumber,
            lotNumber: product.lotNumber,
            sourceLocation: product?.sourceLocation,
            isLotNumber: product?.isLotNumber,
            actualQuantity: actualQuantity.toString(),
            isLotValueUpdated: product?.isLotValueUpdated || false,
            isValidLotSerialCombination: product?.isValidLotSerialCombination,
            iscatalog: product?.iscatalog,
            previousLotNumber: product?.previousLotNumber || '',
            // previousQuantity: product?.previousQuantity || '',
            ...(product.usageExternalId && { usageExternalId: product.usageExternalId }),
            returnType: product?.type?.length > 0 ? product?.type[0]?.value : '',
          });
        }
      });

      let mutation: any = {
        orderId: inventoryDetail.orderId,
        orderExternalId: inventoryDetail.orderExternalId,
        salesRepId: inventoryDetail.salesRepId,
        formType: 'Update',
        // returnType: inventoryDetail.returnType,
      };
      if (type === 'Returning') {
        mutation = {
          ...mutation,
          lineItems: payload,
        };
      } else {
        mutation = {
          ...mutation,
          receivedLineItems: payload,
        };
      }
      setLoader(true);
      createUpdateInventoryReturn({
        variables: mutation,
      })
        .then((res: any) => {
          if (
            res &&
            res.data &&
            res.data.createUpdateInventoryReturnLineItems.message === 'success'
          ) {
            if (!lotValCase) {
              setTimeout(() => {
                // setLoader(false);
                openSnackBar(
                  NOTIFY_TYPE.SUCCESS,
                  'Line items for Return Inventory updated successfully'
                );
              }, 1000);
            }
            refetch();
          }
        })
        .catch(() => {
          setLoader(false);
          setTimeout(() => {
            openSnackBar(
              NOTIFY_TYPE.ERROR,
              'Error:  We are having issue updating the line items for Return Inventory'
            );
          }, 1000);
        });
    }
  };

  useEffect(() => {
    if (
      items?.length > 0 &&
      (inventoryDetail?.type === 'Pickup Return' ||
        inventoryDetail?.type === 'Inventory Return' ||
        inventoryDetail?.type === 'Standard Return') &&
      type !== 'Failed' &&
      type !== 'Processed' &&
      partsLotDetails?.getPartWithLotDetails?.length > 0
    ) {
      let isLotValueUpdated = false;
      items.forEach((i: any) => {
        const product: any = items?.find(
          (item: any) =>
            item.lotNumber === i.lotNumber &&
            item.productNumber === i.productNumber &&
            item.externalId !== i.externalId &&
            item.iscatalog &&
            item.sourceLocation === i.sourceLocation &&
            partsLotDetails?.getPartWithLotDetails?.length > 0
        );

        if (
          product &&
          i.isValidLotSerialCombination &&
          Number(i.availableQuantity) > 0 &&
          !product.isValidLotSerialCombination
        ) {
          product.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
          product.isLotValueUpdated = true;
          isLotValueUpdated = true;
        } else {
          const lotOptions = partsLotDetails?.getPartWithLotDetails;
          const availableLots: any = lotOptions.filter(
            (item: any) =>
              item.lotNumber === i.lotNumber &&
              !i.isValidLotSerialCombination &&
              item.sourceLocation === i.sourceLocation &&
              i.iscatalog &&
              Number(item.availableqty) > 0 &&
              item.productNumber === i.productNumber
          );

          if (
            (!product &&
              i.iscatalog &&
              !i.isValidLotSerialCombination &&
              Number(i.availableQuantity) > 0) ||
            availableLots?.length > 0
          ) {
            // eslint-disable-next-line no-param-reassign
            i.isLotValueUpdated = true;
            isLotValueUpdated = true;
          }
        }
      });
      if (isLotValueUpdated) {
        if (items.length > 0 && inventoryDetail?.status !== 'Failed') {
          setItems(items || []);
          setTimeout(() => {
            handleUpdates(true);
          }, 2000);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, partsLotDetails]);

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout;
    if (lotChange === 1) {
      // eslint-disable-next-line prefer-const
      delayDebounceFn = setTimeout(() => {
        handleUpdates();
      }, 2000);
      setLotChange(0);
    }
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    // return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, lotChange]);
  const getFilterApiValue = (filterValues: DynamicValue): DynamicValue => {
    let reducedFilterValues = {};
    if (filterValues) {
      Object.keys(filterValues).forEach((key: string) => {
        if (Array.isArray(filterValues[key])) {
          const values = filterValues[key].map((item: SelectedData) => item.value);
          if (values && values.length > 0) {
            reducedFilterValues = { ...reducedFilterValues, [key]: [...values] };
          }
        } else if (key !== '__typename' && filterValues[key]) {
          reducedFilterValues = { ...reducedFilterValues, [key]: filterValues[key] };
        }
      });
      const filterData = { ...reducedFilterValues };
      return filterData;
    }
    return {};
  };

  const handleFilter = (filterValues: DynamicValue | null, filterName?: string): void => {
    const updatedFilterName = filterName || '';
    const filter = {
      filterValues,
      filterName: updatedFilterName,
    };
    setSelectedFilter(filter);
    if (filterValues) {
      const filters = getFilterApiValue(filterValues);
      setSelectedFilterApiValues(filters);
    }
  };

  const handleAddParts = (): void => {
    refetch();
    setShowSearchCatalogModal(true);
  };

  const handleDragOnHand = (result: any): void => {
    if (!result.destination) {
      return;
    }
    const listItems =
      type === 'Returning'
        ? [...inventoryDetail.lineItems]
        : [...inventoryDetail.receivedLineItems];
    const reorderedItem = listItems.splice(result.source.index, 1);
    listItems.splice(result.destination.index, 0, ...reorderedItem);
  };
  const updateProducts: Array<any> = items;
  const handleCount = (event: any, data: { value: string; number: number }, product: any): void => {
    const updatedList = [...items];
    updatedList.forEach((element: any) => {
      if (
        product.productId === element.productId &&
        product.usageExternalId === element.usageExternalId &&
        product.lotNumber === element.lotNumber
      ) {
        const packContent =
          element?.salesUnitOfMeasure === 'PK' || element?.salesUnitOfMeasure === 'BX';

        const packOfContentCount = product?.packContent > 0 ? product?.packContent || 1 : 1;

        if (data.value !== '' && data.number > 0) {
          element.counterValue = data.number < MAX_QUANTITY ? data.number : MAX_QUANTITY;
          if (
            userInfo?.businessunit === 'Sports Medicine' &&
            element.productFamily === 'SPTSIMPLANTS' &&
            packContent
          ) {
            element.actualQuantity = element.counterValue * packOfContentCount;
          } else {
            element.actualQuantity = element.counterValue;
          }
          element.changed = true;
          // element.previousQuantity =  element.quantity;
        } else {
          element.counterValue = '';
          element.changed = false;
        }
      }
    });
    setItems(updatedList);
    handleUpdates();
  };
  const handleCountalert = (
    event: any,
    data: { value: string; number: number },
    product: any
  ): void => {
    if (product?.productOnHold) {
      openSnackBar(
        NOTIFY_TYPE.ERROR,
        'This Product/Lot/Serial is currently on hold. Remove this Product/Lot/Serial to advance this order.'
      );
    }
    handleCount(event, data, product);
  };
  const handleDelete = (product: any): void => {
    // empty/clear quantity, it will delete ib Backend
    let counter = 0;
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === product.productId &&
        i.usageExternalId === product.usageExternalId &&
        product.lotNumber === i.lotNumber
      ) {
        i.counterValue = 0;
        i.changed = true;
        i.deleted = true;
      }
      counter += i.counterValue;
    });
    setItems(listItems || []);
    handleUpdates();
  };

  const handleSelectDropdownSoureLocation = (data: any, id: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (i.externalId === id) {
        i.changed = true;
        i.sourceLocation = data?.selection[0]?.value;
        //  i.lotNumber = '';
      }
    });
    setItems(listItems || []);
    handleUpdates();
  };
  const handleSelectDropdownItem = (data: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === item.productId &&
        i.usageExternalId === item.usageExternalId &&
        item.lotNumber === i.lotNumber
      ) {
        i.returnReason = data.selection;
        i.changed = true;
      }
    });
    setItems(listItems || []);
    handleUpdates();
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleSuggestionSelect = (suggestion: any, lineItem: any) => {
    const listItems = [...items].map((i: any) => {
      if (i.uniqueId === lineItem.uniqueId) {
        const product: any = items?.find(
          (value: any) =>
            value.lotNumber === i.lotNumber &&
            value.productNumber === i.productNumber &&
            value.externalId !== i.externalId &&
            value.sourceLocation === i.sourceLocation
        );

        if (product && product.iscatalog && !product.isValidLotSerialCombination) {
          product.isLotValueUpdated = true;
          product.caseUsageSubInventoryCode = lineItem.caseUsageSubInventoryCode;
        }

        return {
          ...i,
          previousLotNumber: i?.lotNumber?.trim(),
          isLotValueUpdated: i?.lotNumber?.trim() !== suggestion?.label?.trim(),
          lotNumber: suggestion?.label?.trim(),
          // caseUsageSubInventoryCode: Number(suggestion.availableqty)
          //   ? suggestion.subInventoryCode
          //   : '',
        };
      }
      return i;
    });
    setItems(listItems || []);
    setLotChange(1);
    setLoader(true);
    // saveParts(true);
    // handleAddParts()
  };

  const handleSelect = (event: any, data: any, id: string): void => {
    const listItems = items.map((i: any) => {
      if (i.uniqueId === id) {
        const product: any = items?.find(
          (value: any) =>
            (value.lotNumber === i.lotNumber ||
              value.lotNumber === data?.selection?.[0]?.lotNumber) &&
            value.productNumber === i.productNumber &&
            value.externalId !== i.externalId &&
            value.sourceLocation === i.sourceLocation
        );

        let maxQuantity = MAX_QUANTITY;
        const qty = i.quantity;
        const actualqty = i.actualQuantity;
        let newLotNumber = '';
        if (data.selection && data.selection[0]) {
          const { availableqty, lotNumber } = data.selection[0];
          if (lotNumber === i.lotNumber) {
            // Edit Same Lot
            maxQuantity = Number(availableqty) + Number(i.originalQty);
          } else {
            // Selected New Lot
            maxQuantity = Number(availableqty);
            newLotNumber = lotNumber;
            // qty = 0; // Initiate
            // actualqty = 0;
          }
        }
        if (product) {
          product.isLotValueUpdated = true;
          product.caseUsageSubInventoryCode = i.caseUsageSubInventoryCode;
        }
        if (
          inventoryDetail?.status === 'Failed' &&
          i?.iscatalog === true &&
          data.selection &&
          data.selection[0]
        ) {
          i.isLotValueUpdated = true;
        }
        return {
          ...i,
          lotNumber: newLotNumber,
          selectedLot: data.selection || [],
          previousLotNumber: i?.lotNumber?.trim(),
          maxQuantity,
          quantity: qty,
          actualQuantity: actualqty,
        };
      }
      return i;
    });

    setItems(listItems || []);
    setLotChange(1);
    // if (data.number !== 0) {
    //   saveParts(true);
    // }

    // listItems.forEach((i: any) => {
    //   if (i.quantity === 0 && i.uniqueId === id) {
    //     setOpenSaveConfirmModal(true);
    //     setModalUniqueId(id);
    //   }
    // });
    // if (data.number !== 0) {
    //   saveParts(true);
    // }
  };

  const handleTypeSelectDropdownItem = (data: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === item.productId &&
        i.usageExternalId === item.usageExternalId &&
        item.lotNumber === i.lotNumber
      ) {
        i.type = data.selection;
        i.changed = true;
      }
    });
    setItems(listItems || []);
    handleLineItemActions(listItems);
    handleUpdates();
  };

  const handleTrackingNumber = (event: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (
        i.productId === item.productId &&
        i.usageExternalId === item.usageExternalId &&
        item.lotNumber === i.lotNumber
      ) {
        i.trackingNumber = event.target.value;
        i.changed = true;
      }
    });
    setItems(listItems || []);
    setLotChange(1);
  };

  const handleUserWorkflowAction = (changeItem: any): void => {
    const listItemsF = [...items];
    listItemsF.forEach((e: any) => {
      if (
        e.productId === changeItem.productId &&
        e.caseUsageExternalId === changeItem.caseUsageExternalId
      ) {
        e.quantity = 0;
        e.actualQuantity = 0;
        e.counterValue = 0;
        e.changed = true;
        e.deleted = true;
      }
    });
    setItems(listItemsF);
    handleUpdates();
  };

  const iconPath = getEventIcon(CYCLE_COUNT);

  const setSearch = (value: string): void => {
    setLoader(true);
    setSearchText(value);
    if (type === 'Processed' || type === 'Failed') {
      refetchProcessedEventDetails();
    } else refetchEventDetails();
  };

  const debouncedFetchRequestedItems = useCallback(debounced(500, setSearch), [
    debounced,
    setSearch,
  ]);
  const handleLotSerialNumber = (event: any, item: any): void => {
    const listItems = [...items];
    listItems.forEach((i: any) => {
      if (i.productId === item.productId && item.lotNumber === i.lotNumber) {
        i.lotNumber = event.target.value;
        i.changed = true;
      }
    });
    setItems(listItems || []);
    setLotChange(1);
  };

  const handleSearch = useCallback(
    (_event: { persist: () => void }, searchedItem: any) => {
      const { value } = searchedItem;
      _event.persist();
      debouncedFetchRequestedItems(value);
    },
    [debouncedFetchRequestedItems]
  );

  const headerActions = (): ReactNode => (
    <PageHeaderControl>
      <ButtonGroup variant="list" id="button-group-page-header-actions">
        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={quantityCount}
            color="grey"
            textColor="white"
            cartTitle="Returning"
          />
        </div>
        <div style={{ marginRight: '10px' }}>
          <CartComponent
            count={quantityRecCount}
            color="grey"
            textColor="white"
            cartTitle="Received"
          />
        </div>
        <Button
          iconCategory="utility"
          iconSize="small"
          className="slds-text-color_default"
          label="Add"
          iconName="add"
          iconPosition="left"
          // disabled={isOrder }
          disabled={
            // visualIndicator.toLowerCase() === 'requested' ||
            visualIndicator.toLowerCase() === 'approved' ||
            visualIndicator.toLowerCase() === 'cancelled' ||
            isOrder ||
            viewOnly
          }
          style={{ fontWeight: 'bold' }}
          onClick={handleAddParts}
        />
      </ButtonGroup>
    </PageHeaderControl>
  );

  const isOps = isUserOps || is3PLOps || isCSROps;

  return (
    <IconSettings iconPath="/icons">
      <SnackBar open={open} notification={notification} />
      {(isLoading || loading || processedLoading || partsLoader) && <Spinner />}
      <PageHeader
        className="events_header"
        icon={
          <img
            src={iconPath}
            className="logo-image"
            alt="company"
            style={{ width: '2.75rem', height: '2.75rem' }}
          />
        }
        onRenderActions={headerActions}
        variant="record-home"
        title="Inventory"
      />
      {showSearchCatalogModal && (
        <InventoryReturnAddItems
          isOpen={showSearchCatalogModal}
          refetch={refetch}
          handleClose={onHandleCloseSearchCatalogModal}
          inventoryReturnDetail={inventoryDetail}
          isEventInventoryFlow={isEventInventoryFlow}
          selectedFilter={selectedFilter}
          handleFilter={handleFilter}
          type={type}
          quantityCount={quantityCount}
        />
      )}
      <div className="order-list-container">
        <DragDropContext onDragEnd={handleDragOnHand}>
          <Droppable droppableId="items">
            {(provided: any): any =>
              type === 'Returning' ? (
                <div className="inventory-table-container">
                  <div className={`parts-table-header ${tableClass} `}>
                    <p>Part</p>
                    <p style={{ textAlign: 'left' }}>Lot/Serial</p>
                    {<p style={{ textAlign: 'center' }}>Expiration Date</p>}
                    <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    <p style={{ textAlign: 'center' }}>Qty</p>
                    {/* <p style={{ textAlign: 'center' }}>Return Reason</p> */}
                    <p style={{ textAlign: 'center', width: '100%' }}>Source Location</p>
                    {!isPickupReturnType && <p style={{ textAlign: 'center' }}>Tracking #</p>}
                    {!isUserOps && <p style={{ textAlign: 'center' }}>Return Type</p>}
                    <p style={{ textAlign: 'center' }}>Action</p>
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div className={`parts-table-header ${tableClass} `}>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                            className={
                              (inventoryDetail?.type === 'Pickup Return' ||
                                inventoryDetail?.type === 'Inventory Return' ||
                                inventoryDetail?.type === 'Standard Return') &&
                              !lineItem?.isValidLotSerialCombination &&
                              lineItem.iscatalog &&
                              inventoryDetail?.status !== 'Completed' &&
                              lineItem.lotValidation === 'true'
                                ? 'redPart'
                                : ''
                            }
                          >
                            {`${lineItem.productNumber} ${
                              isOrder ? lineItem.productdescription : lineItem.productDescription
                            }`}
                            {lineItem?.productOnHold ? (
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{ marginLeft: '8px' }}
                              />
                            ) : (
                              ''
                            )}
                            {lineItem?.iscatalog && (
                              <img
                                src={catalogInd}
                                className="slds-tooltip-trigger"
                                alt="catalog part"
                                style={{
                                  marginLeft: '25px',
                                  marginRight: '8px',
                                  cursor: 'default',
                                }}
                                title="This part was selected from the Catalog"
                              />
                            )}
                            <br />
                          </div>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            <div
                              className="vertical-align-middle"
                              style={{ paddingLeft: '10px', marginLeft: '-10%' }}
                            >
                              {(lineItem?.iscatalog && inventoryDetail?.status !== 'Failed') ||
                              (lineItem?.iscatalog &&
                                inventoryDetail?.status !== 'Failed' &&
                                lineItem.lotValidation === 'false') ? (
                                // eslint-disable-next-line react/jsx-indent
                                <InputWithAutoSuggestion
                                  disabled={
                                    (lineItem &&
                                      lineItem.lineVisualIndicator &&
                                      lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                    (visualIndicator.toLowerCase() === 'requested' &&
                                      isUserOps &&
                                      viewOnly) ||
                                    visualIndicator.toLowerCase() === 'approved' ||
                                    visualIndicator.toLowerCase() === 'cancelled' ||
                                    isOrder
                                  }
                                  lineItem={lineItem}
                                  handleSuggestionSelect={handleSuggestionSelect}
                                />
                              ) : (
                                <Combobox
                                  disabled={
                                    (lineItem &&
                                      lineItem.lineVisualIndicator &&
                                      lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                    (visualIndicator.toLowerCase() === 'requested' &&
                                      isUserOps &&
                                      viewOnly) ||
                                    visualIndicator.toLowerCase() === 'approved' ||
                                    visualIndicator.toLowerCase() === 'cancelled' ||
                                    isOrder
                                  }
                                  id="lotNumber"
                                  events={{
                                    onSelect: (event: any, data: any): void =>
                                      handleSelect(event, data, lineItem.uniqueId),
                                  }}
                                  labels={{
                                    placeholder: 'Select',
                                    noOptionsFound: 'No data found',
                                  }}
                                  placeholder="Select"
                                  options={
                                    lineItem.sourceLocation && lineItem?.lotOptions
                                      ? lineItem.lotOptions
                                      : []
                                  }
                                  selection={lineItem.selectedLot || []}
                                  variant="readonly"
                                />
                              )}
                            </div>
                          </div>
                          {
                            <div
                              style={{
                                paddingLeft: '5px',
                                textAlign: 'center',
                                fontWeight: 'bold',
                              }}
                            >
                              {lineItem.expirationDate
                                ? moment(lineItem.expirationDate)
                                    .utc()
                                    .format('MM/DD/YYYY')
                                : '--'}
                            </div>
                          }
                          <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                            <EventStatusBadge
                              status={isOrder ? lineItem.status : lineItem.lineVisualIndicator}
                            />
                          </div>
                          <div style={{ paddingLeft: '5px', textAlign: 'center' }}>
                            <div className="row-control">
                              <Input
                                id="counter-input-3"
                                minValue={1}
                                maxValue={
                                  (inventoryDetail?.type === 'Pickup Return' ||
                                    inventoryDetail?.type === 'Inventory Return' ||
                                    inventoryDetail?.type === 'Standard Return') &&
                                  !lineItem?.isValidLotSerialCombination &&
                                  lineItem.iscatalog &&
                                  inventoryDetail?.status !== 'Completed' &&
                                  lineItem.lotValidation === 'true'
                                    ? MAX_QUANTITY
                                    : Number(lineItem.maxQuantity)
                                }
                                disabled={
                                  (lineItem &&
                                    lineItem.lineVisualIndicator &&
                                    lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                  (!isOps && visualIndicator.toLowerCase() === 'requested') ||
                                  visualIndicator.toLowerCase() === 'approved' ||
                                  visualIndicator.toLowerCase() === 'cancelled' ||
                                  isOrder ||
                                  viewOnly
                                }
                                onChange={(
                                  event: any,
                                  data: { value: string; number: number }
                                ): void => {
                                  handleCountalert(event, data, lineItem);
                                }}
                                value={lineItem.counterValue}
                                variant="counter"
                              />
                            </div>
                          </div>
                          {/* <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                            <Combobox
                              id="combobox-readonly-single"
                              menuPosition="overflowBoundaryElement"
                              disabled={
                                (lineItem &&
                                  lineItem.lineVisualIndicator &&
                                  lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                visualIndicator.toLowerCase() === 'approved' ||
                                visualIndicator.toLowerCase() === 'cancelled' ||
                                isOrder || !isUserOps
                              }
                              events={{
                                onSelect: (_event: any, data: any): void =>
                                  handleSelectDropdownItem(data, lineItem),
                              }}
                              labels={{
                                placeholder: 'Select',
                              }}
                              options={RETURNS_REASON_OPTION || []}
                              selection={
                                typeof lineItem.returnReason === 'string'
                                  ? [{ label: lineItem.returnReason, value: lineItem.returnReason }]
                                  : lineItem.returnReason || []
                              }
                              variant="readonly"
                              // defaultValue={userAction}
                            />
                          </div> */}
                          <div className="row-control widthAuto">
                            {lineItem?.sourceLocation ? (
                              <div className="vertical-align-middle" style={{ marginLeft: '10%' }}>
                                {lineItem.sourceLocation}
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                          {/* ) : ('')} */}
                          {!isPickupReturnType && (
                            <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                              <Input
                                value={lineItem.trackingNumber ? lineItem.trackingNumber : ''}
                                onChange={(_event: any): void =>
                                  handleTrackingNumber(_event, lineItem)
                                }
                                autoComplete="off"
                                id="unique-id-4"
                                disabled={
                                  (lineItem &&
                                    lineItem.lineVisualIndicator &&
                                    lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                  //    visualIndicator.toLowerCase() === 'requested' ||
                                  visualIndicator.toLowerCase() === 'approved' ||
                                  visualIndicator.toLowerCase() === 'cancelled' ||
                                  isOrder ||
                                  viewOnly
                                }
                              />
                            </div>
                          )}
                          {/* {lineItem.type &&
                          lineItem.workflowAction &&
                          lineItem.workflowAction !== WORKFLOW_ACTION.NO_ACTION &&
                          lineItem.workflowAction !== 'NoAction' ? (
                            <div
                              className="vertical-align-middle"
                              style={{ alignSelf: 'center', margin: 'auto' }}
                            >
                              <p>
                                <EventStatusBadge status={type} />
                              </p>
                            </div>
                          ) : (
                            <div className="vertical-align-middle" style={{ paddingLeft: '10px' }}>
                              <Combobox
                                id="combobox-readonly-single"
                                menuPosition="overflowBoundaryElement"
                                disabled={viewOnly}
                                events={{
                                  onSelect: (_event: any, data: any): void =>
                                    handleTypeSelectDropdownItem(data, lineItem),
                                }}
                                labels={{
                                  placeholder: 'Select',
                                }}
                                options={reviewReturnList || []}
                                selection={
                                  typeof lineItem.type === 'string' && lineItem?.type !== 'Return'
                                    ? [{ label: lineItem.type, value: lineItem.type }]
                                    : lineItem.type || []
                                }
                                variant="readonly"
                                // defaultValue={userAction}
                              />
                            </div>
                          )} */}
                          <div
                            className="vertical-align-middle"
                            style={{ paddingLeft: '5px', textAlign: 'center' }}
                          >
                            <Button
                              disabled={
                                (lineItem &&
                                  lineItem.lineVisualIndicator &&
                                  lineItem.lineVisualIndicator.toLowerCase() === 'approved') ||
                                (visualIndicator.toLowerCase() === 'requested' &&
                                  isUserOps &&
                                  viewOnly) ||
                                visualIndicator.toLowerCase() === 'approved' ||
                                visualIndicator.toLowerCase() === 'cancelled' ||
                                isOrder
                              }
                              iconCategory="utility"
                              iconName="delete"
                              iconSize="large"
                              iconVariant="bare"
                              variant="icon"
                              onClick={(): void => handleDelete(lineItem)}
                            />
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              ) : (
                <div className="inventory-table-container">
                  <div className={`parts-table-header ${tableClass} `}>
                    <p>Part</p>
                    <p style={{ textAlign: 'left' }}>Lot/Serial</p>
                    {!isPickupReturnType && <p style={{ textAlign: 'center' }}>Expiration Date</p>}
                    <p style={{ textAlign: 'left', marginLeft: '25px' }}>Status</p>
                    <p style={{ textAlign: 'center' }}>Returning Qty</p>
                    <p style={{ textAlign: 'center' }}>Received Qty</p>
                    <p style={{ textAlign: 'center' }}>ERP Order</p>
                    <p style={{ textAlign: 'center' }}>Received</p>
                  </div>
                  {items?.length > 0 &&
                    items.map((lineItem: any) => {
                      return !lineItem.deleted ? (
                        <div className={`parts-table-header ${tableClass} `}>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem.productNumber} ${
                              isOrder ? lineItem.productdescription : lineItem.productDescription
                            }`}
                            {lineItem.productOnHold ? (
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{ marginLeft: '8px' }}
                              />
                            ) : (
                              ''
                            )}
                            {lineItem?.iscatalog && (
                              <img
                                src={catalogInd}
                                className="slds-tooltip-trigger"
                                alt="catalog part"
                                style={{
                                  cursor: 'default',
                                }}
                                title="This part was selected from the Catalog"
                              />
                            )}
                            <br />
                          </div>
                          <div
                            style={{ paddingLeft: '5px', textAlign: 'left', fontWeight: 'bold' }}
                          >
                            {`${lineItem?.lotNumber || '--'}`}
                          </div>
                          {!isPickupReturnType && (
                            <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                              {lineItem.expirationDate
                                ? moment(lineItem.expirationDate)
                                    .utc()
                                    .format('MM/DD/YYYY')
                                : '--'}
                            </div>
                          )}
                          <div style={{ textAlign: 'center', display: 'flex' }}>
                            <EventStatusBadge
                              status={isOrder ? lineItem.status : lineItem.lineVisualIndicator}
                            />
                            {lineItem.productOnHold ? (
                              <img
                                src={holdIconPath}
                                className="logo-image"
                                alt="company"
                                style={{
                                  marginLeft: '10px',
                                  width: '16px',
                                  height: '17px',
                                }}
                              />
                            ) : (
                              ''
                            )}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {`${lineItem?.quantity || ''}`}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {`${lineItem?.receivedQuantity || 0}`}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {`${lineItem?.erpOrderNumber || ''}`}
                          </div>
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {lineItem.receivedDate
                              ? moment(lineItem.receivedDate)
                                  .utc()
                                  .format('MM/DD/YYYY')
                              : '--'}
                          </div>
                        </div>
                      ) : null;
                    })}
                </div>
              )
            }
          </Droppable>
        </DragDropContext>
      </div>
    </IconSettings>
  );
};

/** Custom Header on InventoryRequestDetailCatalog Page **/
export default InventoryRequestTable;
