/* eslint-disable no-shadow */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/**
 * Module for showing Inventory Details.
 * @module src/Orders/UsageDetails
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable import/no-cycle */
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps, useParams, withRouter } from 'react-router-dom';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { getUserInfo } from '../../../store/ducks/userInfo';
import UsageDetailsHeader, { UsageSheetDetails } from './UsageDetailsHeader';
import './index.scss';
import { GET_CASE_USAGE_SHEET_DETAILS } from '../../../graphql/getCaseUsageSheetDetails';
import UsageTabs from './UsageTabs';
import UsageSheetForm from '../UsageSheetForm';
import { GET_ORDER_DETAILS } from '../../../graphql/getOrderDetails';
import { GET_DETAILS_VIEW_CONFIGURATIONS } from '../../../graphql/getDetailsViewConfigurations';
import {
  GET_WORKFLOW_PERMISSION,
  GET_WORKFLOW_PERMISSIONS,
} from '../../../graphql/workflowPermissions';
import CancelEventModal from '../../CreateEditSurgeryEvent/CancelEventCreationModal';

interface Props extends RouteComponentProps {
  children?: FC;
  externalId?: string;
}
type ReturnParams = {
  externalId: string;
  type: string;
  caseExternalId: string | undefined;
  orderId: string | undefined;
  formData?: string | undefined;
};
const UsageDetails: FC<Props> = ({ location, history, externalId }) => {
  const { state } = location;
  const useParam = useParams<ReturnParams>();
  const parameters = state || useParam;
  const { type, orderId, caseExternalId, formData } = parameters;
  // const { formData } = state;
  const userInfo = useSelector(getUserInfo);
  const [isErpOrder, setIsErpOrder] = useState(false);
  const [isErpOrderFailed, setIsErpOrderFailed] = useState(false);
  const [showUsageForm, setShowUsageForm] = useState(false);
  const [viewOnly, setViewOnly] = useState(false);
  const [isAssignUser, setIsAssignUser] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loader, startLoader] = useState(false);
  const [canceModalVisible, setCanceModalVisible] = useState(false);
  const [details, setDetails] = useState<UsageSheetDetails>();
  const [triggerSubmit, setTriggerSubmit] = useState(0);
  const [tabIndexVal, setTabIndexVal] = useState(0);

  useQuery(GET_WORKFLOW_PERMISSIONS);
  const { data: orderResubmitPermission } = useQuery(GET_WORKFLOW_PERMISSION, {
    fetchPolicy: 'cache-only',
    variables: {
      division: 'Sports Med',
      action: 'enable_order_resubmission',
    },
  });
  const [getUsageDetails, { data: usageDetails, loading: loadingDetails }] = useLazyQuery(
    GET_CASE_USAGE_SHEET_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );
  const [getOrderDetails, { data: orderDetails, loading: loadingOrderDetails }] = useLazyQuery(
    GET_ORDER_DETAILS,
    {
      fetchPolicy: 'no-cache',
    }
  );

  const handleCancelButton = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModal = (): void => {
    setCanceModalVisible(!canceModalVisible);
  };

  const handleCancelModalConfirmPress = (): void => {
    setCanceModalVisible(!canceModalVisible);
    setShowUsageForm(!showUsageForm);
  };

  const handleSubmit = (): void => {
    setTriggerSubmit(triggerSubmit + 1);
  };

  const { data: getDetailsViewConfigurations } = useQuery(GET_DETAILS_VIEW_CONFIGURATIONS);
  const orderDetailsViewconfig =
    details?.diplayOrderType === 'Bill Only'
      ? getDetailsViewConfigurations?.getDetailsViewConfigurations?.billOnly
      : getDetailsViewConfigurations?.getDetailsViewConfigurations?.shipAndBill;
  const handleUsageModalToggle = (): void => {
    setShowUsageForm(!showUsageForm);
  };
  const getDetails = useCallback(() => {
    const { orderId, type, status } = parameters;
    if (type && (type === 'Failed' || type === 'Processed' || type === 'Review') && orderId) {
      // Order (Failed/Processed)
      setIsErpOrder(true);
      setIsErpOrderFailed(type === 'Failed' || (type === 'Review' && status === 'Failed'));
      getOrderDetails({
        variables: {
          id: orderId,
        },
      });
    } else {
      // Order Requests
      const id = externalId || parameters?.externalId;
      if (id) {
        // setTimeout(() => {
        getUsageDetails({
          variables: {
            externalId: id,
          },
        });
        // }, 2000);
      } else {
        // No external id -- navigate to orders
        history.push('./Orders');
      }
    }
  }, [externalId, getOrderDetails, getUsageDetails, history, parameters]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);
  useEffect(() => {
    if (usageDetails) {
      setDetails(usageDetails.getCaseUsageSheetDetails);
    } else if (orderDetails) {
      setDetails(orderDetails.getOrderDetails);
    }
  }, [usageDetails, orderDetails]);
  useEffect(() => {
    if (details) {
      const { eventStatus, status: orderStatus } = details;
      const { type } = parameters;
      setIsAssignUser(
        type === 'Review' &&
          details?.assignedUserId !== userInfo?.id &&
          (userInfo?.personas === 'Agency OPS' ||
            userInfo?.personas === 'Agency CSR' ||
            userInfo?.personas === 'CSR' ||
            userInfo?.personas === 'Agency OPS;Agency CSR' ||
            userInfo?.personas === 'CSR;Branch Op' ||
            userInfo?.personas === 'Branch Op;CSR' ||
            userInfo?.personas === 'Branch Op' ||
            userInfo?.personas === '3PL Ops' ||
            !!userInfo?.personas?.toLowerCase().includes('csr') ||
            !!userInfo?.personas?.toLowerCase().includes('branch op'))
      );

      const status = isErpOrder ? orderStatus || eventStatus : eventStatus;
      if (
        status === 'Completed' ||
        status === 'Submitted' ||
        status === 'Cancelled' ||
        (status === 'Requested' && parameters.type !== 'Review') ||
        status === 'Closed' ||
        (status === 'Failed' &&
          userInfo?.personas !== undefined &&
          userInfo?.personas !== 'Agency OPS' &&
          userInfo?.personas !== 'Agency CSR' &&
          userInfo?.personas !== 'CSR' &&
          userInfo?.personas !== 'Agency OPS;Agency CSR' &&
          userInfo?.personas !== 'CSR;Branch Op' &&
          userInfo?.personas !== 'Branch Op;CSR' &&
          userInfo?.personas !== 'Branch Op' &&
          userInfo?.personas !== '3PL Ops' &&
          !userInfo?.personas?.toLowerCase().includes('csr') &&
          !userInfo?.personas?.toLowerCase().includes('branch op')) ||
        status === 'Entered'
      ) {
        setViewOnly(true);
      }
    }
  }, [details, isErpOrder, parameters, userInfo]);
  const refreshUsageDetails = (): void => {
    getDetails();
  };
  const selectedUsageTab = (tabIndex: any): void => {
    setTabIndexVal(tabIndex);
  };
  return (
    <IconSettings iconPath="/icons">
      {/* <SnackBar open={open} notification={notification} /> */}
      {/* {loadingDetails || (loadingOrderDetails && <Spinner />)} */}
      {details && (
        <>
          {!externalId && (
            <UsageDetailsHeader
              usageDetails={details}
              handleUsageSheetEdit={handleUsageModalToggle}
              refetch={refreshUsageDetails}
              viewOnly={viewOnly}
              isAssignUser={isAssignUser}
              isERPOrder={isErpOrder}
              isERPOrderFailed={isErpOrderFailed}
              detailsConfig={orderDetailsViewconfig?.headers}
              type={parameters.type}
              handleSubmit={handleSubmit}
              tabIndexVal={tabIndexVal}
            />
          )}
          <div className="slds-m-around_small slds-m-bottom_none">
            <UsageTabs
              usageDetails={details}
              orderDetails={orderDetails?.getOrderDetails}
              refetch={refreshUsageDetails}
              eventType={details?.eventType}
              viewOnly={viewOnly}
              isAssignUser={isAssignUser}
              isERPOrder={isErpOrder}
              isERPOrderFailed={isErpOrderFailed}
              detailsConfig={orderDetailsViewconfig}
              orderResubmitPermission={orderResubmitPermission}
              type={parameters.type}
              screenLoading={loadingDetails || loadingOrderDetails}
              triggerSubmit={triggerSubmit}
              selectedUsageTab={selectedUsageTab}
            />
          </div>
        </>
      )}
      {showUsageForm && (
        <UsageSheetForm
          isOpen={showUsageForm}
          handleUsageSheetEdit={handleUsageModalToggle}
          toggleOpen={handleUsageModalToggle}
          handleCancelButton={handleCancelButton}
          formData={formData}
          isAssignUser={isAssignUser}
          editDetails={details}
          orderType={details?.diplayOrderType || ''}
          refetchDetails={refreshUsageDetails}
          isERPOrder={isErpOrder}
          startLoader={startLoader}
          type={parameters.type}
        />
      )}
      {canceModalVisible && (
        <CancelEventModal
          canceModalVisible={canceModalVisible}
          handleCancelModal={handleCancelModal}
          handleCancelModalConfirmPress={handleCancelModalConfirmPress}
          edit
        />
      )}
    </IconSettings>
  );
};

export default withRouter(UsageDetails);
