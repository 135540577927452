/* eslint-disable react/jsx-curly-newline */
/**
 * Module defining entire routes for the application
 * @module src/components/Image
 */
import React, { ReactElement } from 'react';
import { Route, BrowserRouter as Router, Switch, RouteProps, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAccessToken } from '../store/ducks/accessToken';
import Login from './Login';
import SurgicalDetails from './SurgicalDetail';
import EventsTab from './Events/EventsTab';
import Header from './App/Header';
import Inventory from './Inventory';
import Orders from './Orders';
import InventoryRequestDetail from './Inventory/InventoryRequestDetail';
import InventoryReturnDetail from './Inventory/InventoryReturnDetail';
import InventoryFavoriteDetail from './Inventory/InventoryFavoriteDetail';
import PartDetails from './Inventory/InventoryPartDetails';
import LotDetails from './Inventory/InventoryLotDetails';
import TransferDetails from './Inventory/Transfer/TransferDetails';
import TranslationTest from './TranslationTest';
import InventoryQueueRequestDetails from './Inventory/InventoryQueue/InventoryDetails';
import InventoryReturnApproval from './Inventory/InventoryReturnApproval';
import CycleCount from './CycleCount/CycleCountTabs';
import UsageDetails from './Usage/UsageDetails';
import CycleCountDetail from './CycleCount/CycleCountDetail';
import NotificationSetting from './NotificationCenter/NotificationSetting';
import NotificationDetails from './NotificationCenter/NotificationDetails';
import NotificationCenter from './NotificationCenter';
import NotificationConfiguration from './NotificationCenter/NotificationConfiguration';
import BusinessConfiguration from './BusinessConfiguration';
import ReservedTransactions from './ReservedTransactions';
import GlobalSearch from './App/GlobalSearch';

function SecuredRoute({ children, ...rest }: RouteProps): ReactElement {
  const accessToken = useSelector(getAccessToken);

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={({ location }): React.ReactNode =>
        accessToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes: React.FunctionComponent = () => {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/">
          <Login />
        </Route>
        <SecuredRoute exact path="/events">
          <EventsTab />
        </SecuredRoute>
        <SecuredRoute path="/events/:tab">
          <EventsTab />
        </SecuredRoute>
        <SecuredRoute exact path="/inventory">
          <Inventory />
        </SecuredRoute>
        <SecuredRoute exact path="/inventory/:tab">
          <Inventory />
        </SecuredRoute>
        <SecuredRoute exact path="/inventory/:tab/:status">
          <Inventory />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryQueueRequestDetails">
          <InventoryQueueRequestDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryTransferDetails/:externalId?/:type?">
          <TransferDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryReturnApproval">
          <InventoryReturnApproval />
        </SecuredRoute>
        <SecuredRoute exact path="/orders">
          <Orders />
        </SecuredRoute>
        <SecuredRoute exact path="/orders/:tab">
          <Orders />
        </SecuredRoute>
        <SecuredRoute exact path="/partDetails">
          <PartDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/lotDetails">
          <LotDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/surgicalDetails/:externalId?/:type?">
          <SurgicalDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryRequestDetails/:externalId?/:type?">
          <InventoryRequestDetail />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryReturnDetails/:externalId?/:type?">
          <InventoryReturnDetail />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryReturnDetails/:externalId?/:orderId?/:type?">
          <InventoryReturnDetail />
        </SecuredRoute>
        <SecuredRoute exact path="/inventoryFavoriteDetails">
          <InventoryFavoriteDetail />
        </SecuredRoute>
        <SecuredRoute exact path="/translationTest">
          <TranslationTest />
        </SecuredRoute>
        <SecuredRoute exact path="/OrdersUsageDetails/:externalId?/:type?">
          <UsageDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/OrdersUsageDetails/:orderId/:type">
          <UsageDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/cycleCount">
          <CycleCount />
        </SecuredRoute>
        <SecuredRoute exact path="/cycleCount/:tab">
          <CycleCount />
        </SecuredRoute>
        <SecuredRoute exact path="/cycleCountDetails/:cycleCountExternalId?">
          <CycleCountDetail />
        </SecuredRoute>
        <SecuredRoute exact path="/notificationSettings">
          <NotificationSetting />
        </SecuredRoute>
        <SecuredRoute exact path="/notificationDetails">
          <NotificationDetails />
        </SecuredRoute>
        <SecuredRoute exact path="/notificationCenter">
          <NotificationCenter />
        </SecuredRoute>
        <SecuredRoute exact path="/notificationConfiguration">
          <NotificationConfiguration />
        </SecuredRoute>
        <SecuredRoute exact path="/configurations">
          <BusinessConfiguration />
        </SecuredRoute>
        <SecuredRoute exact path="/configurations/:tab">
          <BusinessConfiguration />
        </SecuredRoute>
        <SecuredRoute exact path="/reservedTransactions">
          <ReservedTransactions />
        </SecuredRoute>
        <SecuredRoute exact path="/globalsearch">
          <GlobalSearch />
        </SecuredRoute>
      </Switch>
    </Router>
  );
};
export default Routes;
