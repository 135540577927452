/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable dot-notation */
/* eslint-disable no-console */

import React, { FC, ReactNode, useState, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import Spinner from '@salesforce/design-system-react/components/spinner';
import Input from '@salesforce/design-system-react/components/input';
import PageHeader from '@salesforce/design-system-react/components/page-header';
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import Icon from '@salesforce/design-system-react/components/icon';
import PageHeaderControl from '@salesforce/design-system-react/components/page-header/control';
import Button from '@salesforce/design-system-react/components/button';
import Checkbox from '@salesforce/design-system-react/components/checkbox';
import { Tab, Tabs, Paper } from '@material-ui/core';
import Combobox from '@salesforce/design-system-react/components/combobox';
import { NOTIFY_TYPE, LANDING_PAGE_COMMEN_CONFIG } from '../../../util/constants';
import { GET_NOTIFICATION_CONFIGURATION } from '../../../graphql/getNotificationConfiguration';
import CREATE_UPDATE_NOTIFICATION_CONFIG from '../../../graphql/mutations/createUpdateNotificationConfig';
import useSnackBar from '../../../util/customHooks/useSnackBar';
import SnackBar from '../../Shared/SnackBar';
import { getUserId } from '../../../store/ducks/userId';
import { getUserInfo } from '../../../store/ducks/userInfo';
import { GET_ADMIN_FROM_CONFIG } from '../../../graphql/getAdminFormConfigurations';
import CREATE_UPDATE_ADMIN_FORM_CONFIG from '../../../graphql/mutations/updateAdminFormConfigurations';
import DisplayRecordIndicator from '../../Shared/DisplayRecordIndicator';

interface Props {
  type: string;
}

// eslint-disable-next-line no-empty-pattern
const SystemConfiguration: FC<RouteComponentProps> = ({ location }) => {
  const userInfo = useSelector(getUserInfo);
  const [timeStamp, setTimeStamp] = useState<any>('');
  const [
    getNotificationConfigurations,
    {
      data: getNotificationConfigurationData,
      refetch: refetchNotificationConfigurations,
      loading: getNotificationLoader,
    },
  ] = useLazyQuery(GET_ADMIN_FROM_CONFIG, {
    fetchPolicy: 'no-cache',
    variables: {
      screen: 'Global',
    },
  });

  const { open, notification, openSnackBar } = useSnackBar();
  const [input, setInput] = useState<any>({});
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenInventory, setIsOpenInventory] = useState(true);
  const [isOpenOrders, setIsOpenOrders] = useState(true);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [notificationConfigurations, setNotificationconfigurations] = useState<any>(null);
  const [landingPageConfigurations, setLandingPageConfigurations] = useState<any>(null);
  const [createUpdateConfig, { data: notificationConfigurationData, loading }] = useMutation(
    CREATE_UPDATE_ADMIN_FORM_CONFIG
  );

  useEffect(() => {
    getNotificationConfigurations({
      variables: { screen: 'Global' },
    });
  }, [getNotificationConfigurations]);

  useEffect(() => {
    if (
      notificationConfigurationData &&
      notificationConfigurationData.updateAdminFormConfigurations
    ) {
      if (notificationConfigurationData.updateAdminFormConfigurations.message === 'success') {
        openSnackBar(NOTIFY_TYPE.SUCCESS, 'Configuration Settings Saved Successfully');
        refetchNotificationConfigurations();
      }
    }
  }, [notificationConfigurationData]);

  useEffect(() => {
    if (
      getNotificationConfigurationData &&
      getNotificationConfigurationData?.getAdminFormConfigurations[0]?.businessRules
    ) {
      const businessRulesdata =
        getNotificationConfigurationData?.getAdminFormConfigurations[0]?.businessRules;

      setNotificationconfigurations(
        businessRulesdata.sort((a: any, b: any) => a.serialNumber - b.serialNumber)
      );
      const landigPageConfig = businessRulesdata
        ?.filter(
          (itemType: any) =>
            itemType.header === 'Landing Page' && itemType.value_type === 'dropdown'
        )
        ?.sort((a: any, b: any) => a.rules.localeCompare(b.rules));
      setLandingPageConfigurations(landigPageConfig);

      businessRulesdata.forEach((br: any) => {
        if (br.isheader === false) input[br.rules] = br.values;
      });
    } else {
      setNotificationconfigurations([]);
    }
  }, [getNotificationConfigurationData, refetchNotificationConfigurations]);

  const handleCancel = (): void => {
    getNotificationConfigurations({
      variables: { screen: 'Global' },
    });
  };

  const handleSave = (): void => {
    let mutation: any = {};
    const payload: Array<any> = [];
    notificationConfigurations.forEach((e: any): void => {
      payload.push({
        formId: e.formId,
        rules: e.rules,
        isRequired: e.isRequired,
        values: input[e.rules] ? input[e.rules] : '',
      });
    });
    mutation = {
      isBUFormRequired: true,
      screen: 'Global',
      businessrules: payload || [],
    };
    createUpdateConfig({
      variables: mutation,
    }).catch(error => {
      const errMsg = error.message.split(':')[1] || 'Something went wrong. Please try again.';
      openSnackBar(NOTIFY_TYPE.ERROR, errMsg);
    });
  };

  const handleChange = (event: any, item: any, toggledProperty: string): void => {
    const updatedNotificationSettings = notificationConfigurations.map((element: any) => {
      if (item.formId) {
        if (item.formId === element.formId && item.fieldName === element.fieldName) {
          if (toggledProperty === 'enabled') {
            if (event.target.checked === false) {
              return { ...element, isRequired: false };
            }
            return { ...element, isRequired: true };
          }
        }
      }
      return element;
    });
    setNotificationconfigurations(updatedNotificationSettings);
  };

  useEffect(() => {
    const createdDateTime = notificationConfigurations?.sort(
      (a: any, b: any) => new Date(a.createdDate - b.createdDate)
    );
    const updateDateTime = notificationConfigurations?.sort(
      (a: any, b: any) => new Date(a.updatedDate - b.updatedDate)
    );
    const record = {
      createdBy:
        createdDateTime?.length > 0 && createdDateTime[0]?.createdBy
          ? createdDateTime[0]?.createdBy
          : 'System',
      createdDate: createdDateTime?.length > 0 && createdDateTime[0]?.createdDate,
      updatedBy: updateDateTime?.length > 0 && updateDateTime[0]?.updatedBy,
      updatedDate: updateDateTime?.length > 0 && updateDateTime[0]?.updatedDate,
    };
    setTimeStamp(record);
  }, [notificationConfigurations]);

  const handleBusinessRuleText = (item: any, text: any) => {
    setInput({ ...input, [item.rules]: text });
  };

  const handleSelect = (data: any, name: string) => {
    const { selection } = data;
    setInput({ ...input, [name]: selection[0]?.label });
  };

  const actions = (): ReactNode => (
    <>
      <PageHeaderControl>
        <DisplayRecordIndicator recordInfo={timeStamp} screen="config" />
      </PageHeaderControl>
      <PageHeaderControl>
        <Button
          style={{ color: 'black', marginRight: '10px', fontWeight: 600 }}
          align="right"
          label="Cancel"
          onClick={handleCancel}
        />
        <Button className="yellow-btn" align="right" label="Save" onClick={handleSave} />
      </PageHeaderControl>
    </>
  );

  return (
    <div>
      <IconSettings iconPath="/icons">
        <SnackBar open={open} notification={notification} />
        {(loading || getNotificationLoader) && (
          <Spinner size="large" variant="base" assistiveText={{ label: 'Loading...' }} />
        )}
        <PageHeader
          className="slds-m-around_small slds-m-bottom_none"
          icon={
            <Icon
              assistiveText={{ label: 'Opportunity' }}
              category="standard"
              name="event"
              style={{ backgroundColor: '#FFB500' }}
            />
          }
          onRenderActions={actions}
          title={
            <div className="notification_header">
              <span className="slds-col event_id">Global Configurations</span>
            </div>
          }
          variant="record-home"
        />
        <div
          className="slds-page-header__detail-row slds-grid slds-m-around_small slds-m-top_none transferDetails"
          style={{ display: 'block' }}
        >
          <div className="notification-config-container">
            <p className="titleConfig">Business Rules</p>
            <div className="notification-cols-header-global options-table-row">
              <p />
            </div>
            {notificationConfigurations &&
              notificationConfigurations
                .filter((itemType: any) => itemType.header !== 'Landing Page')
                .map((item: any) => (
                  <div
                    className="notification-cols-header-global options-table-row"
                    style={{ paddingLeft: '60px' }}
                  >
                    <span style={{ fontSize: 14, marginBottom: '10px', width: '324px' }}>
                      {` ${item.rules}`}
                      <span id="tooltip">
                        <Icon
                          category="utility"
                          name="info"
                          size="x-small"
                          style={{
                            fill: 'rgb(133 125 125)',
                            marginBottom: '3px',
                            marginLeft: '5px',
                          }}
                        />
                        <span style={{ padding: '10px' }} className="tooltiptext">
                          {item.description}
                        </span>
                      </span>
                    </span>
                    <span style={{ fontSize: 14, marginBottom: '10px', width: '300px' }}>
                      {item.value_type === 'textarea' ? (
                        <Input
                          placeholder="Enter Email Recipients"
                          onChange={
                            (event: any): void => handleBusinessRuleText(item, event.target.value)
                            // eslint-disable-next-line react/jsx-curly-newline
                          }
                          value={input[item.rules]}
                          // maxLength="10"
                          disabled={item.isRequired === false}
                          type="string"
                        />
                      ) : (
                        <Checkbox
                          labels={{
                            toggleEnabled: '',
                            toggleDisabled: '',
                          }}
                          variant="toggle"
                          checked={item.isRequired}
                          onChange={(e: any): void => {
                            handleChange(e, item, 'enabled');
                          }}
                        />
                      )}
                    </span>
                  </div>
                ))}
          </div>
          <div className="notification-config-container">
            <p className="titleConfig">Landing Page</p>
            <div className="notification-cols-header-global options-table-row">
              <p />
            </div>
            {landingPageConfigurations?.map((item: any) => (
              <div
                className="notification-cols-header-global options-table-row"
                style={{ paddingLeft: '60px' }}
              >
                <span style={{ fontSize: 14, marginBottom: '10px', width: '324px' }}>
                  {` ${item.rules.replace(/;/g, '-')}`}
                </span>
                <span style={{ fontSize: 14, marginBottom: '10px', width: '300px' }}>
                  <Combobox
                    id={item.rules}
                    events={{
                      onSelect: (event: any, data: any): void => handleSelect(data, item.rules),
                    }}
                    labels={{
                      placeholder: `Select ${item.rules}`,
                    }}
                    options={LANDING_PAGE_COMMEN_CONFIG?.filter((dropdownItem: any) =>
                      dropdownItem?.usedFor?.includes(item.rules)
                    )}
                    selection={[{ label: input[item.rules] }]}
                    variant="readonly"
                  />
                </span>
              </div>
            ))}
          </div>
        </div>
      </IconSettings>
    </div>
  );
};

export default withRouter(SystemConfiguration);
